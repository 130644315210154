import { SponsorsQueryResult } from "../lib/types";
import { useSponsorsQuery } from "../lib/hooks";

const Sponsors = ({ sponsors }: { sponsors?: SponsorsQueryResult }) => {
  const sponsorsQuery = useSponsorsQuery({
    initialData: sponsors,
  });

  const isDataValid =
    sponsorsQuery.data?.mediaPartners?.length > 0 ||
    sponsorsQuery.data?.majorPartners?.length > 0;

  if (!isDataValid) {
    return null;
  }

  return (
    <div className={`relative p-6`}>
      <div className="flex flex-wrap justify-center">
        {sponsorsQuery.isLoading && <p>Loading...</p>}
        <p className="w-full my-4 text-center">
          <strong>Major partners</strong>
        </p>
        {sponsorsQuery.data.majorPartners?.map((item) => (
          <a
            href={item.link}
            target="_blank"
            key={item.link}
            aria-label={item.name}
            rel="noreferrer"
          >
            {/* todo: fix this max height hack */}
            <img src={item.image} alt={item.name} className="h-6 mr-4  mb-4" />
          </a>
        ))}
      </div>
      {!sponsorsQuery.isLoading && sponsorsQuery.data.mediaPartners.length > 0 && (
        <p className="w-full my-4 text-center">
          <strong>Media partners</strong>
        </p>
      )}
      <div className="flex flex-wrap justify-center">
        {sponsorsQuery.data.mediaPartners?.map((item) => {
          if (!item.link) return <img src={item.image} />;
          return (
            <a
              href={item.link}
              target="_blank"
              key={item.link}
              aria-label={item.name}
              rel="noreferrer"
            >
              <img
                src={item.image}
                alt={item.name}
                style={{ maxHeight: "80px" }}
                className="mr-4 mb-4"
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Sponsors;
