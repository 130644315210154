import Head from "next/head";

const Meta = ({
  title = undefined,
  description = undefined,
  image = undefined,
  url = undefined,
}) => {
  return (
    <Head>
      <title>{title ? title + " | Mona Foma 2022" : "Mona Foma 2022"}</title>
      <meta
        name="og:title"
        content={title ? title + " | Mona Foma" : "Mona Foma"}
      />
      <meta
        content={title ? title + " | Mona Foma" : "Mona Foma"}
        name="twitter:title"
      />
      <meta name="og:url" content={`${url || "https://monafoma.net.au"}`} />
      <meta
        name="description"
        content={`${
          description
            ? description.replace(/(#|\*|<\/?(\s|\S)*?>)/g, "")
            : description
        }`}
      />
      <meta
        name="og:description"
        content={`${
          description
            ? description.replace(/(#|\*|<\/?(\s|\S)*?>)/g, "")
            : description
        }`}
      />
      <meta
        name="twitter:description"
        content={`${
          description
            ? description.replace(/(#|\*|<\/?(\s|\S)*?>)/g, "")
            : description
        }`}
      />
      <meta
        name="og:image"
        content={`${
          image ||
          "https://mofo2022.s3.ap-southeast-2.amazonaws.com/social/MF22-PA-share.jpg"
        }`}
      />
      <meta
        content={`${
          image ||
          "https://mofo2022.s3.ap-southeast-2.amazonaws.com/social/MF22-PA-share.jpg"
        }`}
        name="twitter:image:src"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@monafoma" />
      <meta name="twitter:creator" content="@monamuseum" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
};

export default Meta;
