const FullScreenBackground = ({
  url = "https://mofo2022.s3.ap-southeast-2.amazonaws.com/overlays/mf22-bg-NEW-2.png",
}) => (
  <div
    className="fixed top-0 left-0 w-full h-full bg-cover bg-no-repeat"
    style={{
      backgroundImage: `url(${url})`,
      backgroundPositionY: "bottom",
    }}
  ></div>
);

export default FullScreenBackground;
