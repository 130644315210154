import ReactMarkdown from "react-markdown";

import { MF22ContentfulProductionSeason } from "../lib/types";
import { filterDateTimesByTessituraPublishWebDateConstraints } from "../lib/filterers";
import { Calendar } from "./Icons";

export default function ProductionSeasonDates({
  productionSeason,
  showLongerSummary,
}: {
  productionSeason: MF22ContentfulProductionSeason;
  showLongerSummary?: boolean;
}) {
  const prodSeasonActiveDateTimes =
    filterDateTimesByTessituraPublishWebDateConstraints(
      productionSeason.dateTimes
    );

  return (
    <div className="flex mb-2">
      <div className="w-4 flex-shrink-0 mr-2">
        <Calendar />
      </div>
      <div>
        {showLongerSummary && productionSeason.datetimesSummaryOverride && (
          <div className="markdown">
            <ReactMarkdown>
              {productionSeason.datetimesSummaryOverride}
            </ReactMarkdown>
          </div>
        )}
        {!showLongerSummary &&
          (productionSeason.dateTimesShortSummaryOverride ||
          productionSeason.datetimesSummaryOverride ? (
            <div className="markdown">
              <ReactMarkdown>
                {productionSeason.dateTimesShortSummaryOverride ||
                  productionSeason.datetimesSummaryOverride}
              </ReactMarkdown>
            </div>
          ) : prodSeasonActiveDateTimes?.length > 1 ? (
            prodSeasonActiveDateTimes.map((dateTime) => (
              <p key={dateTime.tessituraId || dateTime.dateSummary}>
                {dateTime.dateSummary}
              </p>
            ))
          ) : prodSeasonActiveDateTimes?.length === 1 ? (
            `${prodSeasonActiveDateTimes[0].dateSummary}`
          ) : null)}
      </div>
    </div>
  );
}
