export function memeify(text: string): string {
  let memeText: string = "";
  if (text) {
    let textAsArray = text?.split("");
    let uppercase: string[] = [
      "C",
      "G",
      "I",
      "J",
      "L",
      "N",
      "O",
      "P",
      "R",
      "S",
      "T",
      "U",
      "W",
      "X",
      "Y",
      "Z",
    ];

    for (let i = 0; i < textAsArray?.length; i++) {
      let value = textAsArray[i];
      let asUp = value.toUpperCase();
      if (uppercase.includes(asUp)) {
        memeText += asUp;
      } else {
        memeText += value.toLowerCase();
      }
    }
  }
  return memeText;
}
