import React, { useContext } from "react";
import { useRouter } from "next/router";
import { differenceInMinutes } from "date-fns";

import { TessituraCartContext } from "../context/TessituraCartContext";
import MofoContext from "../context/MofoContext";

export function CartContentsButton({ className = "" }: { className?: string }) {
  const router = useRouter();

  const { webCart, webSessionWithExpirations, isLoadingCart } =
    useContext(TessituraCartContext);
  const { isTicketsPage } = useContext(MofoContext);

  const itemCount = webCart?.Products?.length || 0;

  let minutesUntilExpiration;
  if (webSessionWithExpirations?.CartExpiration) {
    const expiration = new Date(webSessionWithExpirations.CartExpiration);
    minutesUntilExpiration = differenceInMinutes(expiration, new Date()) + 1;
  }

  if (
    isLoadingCart ||
    webSessionWithExpirations?.isCompleted ||
    itemCount === 0 ||
    !minutesUntilExpiration
  ) {
    return null;
  }

  const buttonHoverClasses =
    " transition-all duration-500 has-hover:hover:bg-black has-hover:hover:border-r-0 " +
    (isTicketsPage
      ? "has-hover:hover:border-cyan has-hover:hover:text-cyan"
      : "has-hover:hover:border-yellow has-hover:hover:text-yellow");

  return (
    <div
      onClick={() => {
        if (router.pathname.startsWith("/tickets")) {
          router.push("/cart");
        } else {
          // TODO Redirect to absolute URL for cart on tickets subdomain post-dev
          router.push("/tickets/cart");
        }
      }}
      className={`fixed right-0 z-40 top-1/2 ${className}`}
      style={{
        transform: "translateY(-50%)",
      }}
    >
      <button
        className={
          `p-2 pr-1 ${isTicketsPage ? "bg-cyan" : "bg-yellow"} ` +
          `border-2 border-r-0 rounded-l-xl border-black flex flex-col gap-1 items-center ` +
          buttonHoverClasses
        }
      >
        <div className="grid grid-cols-2 gap-2">
          <span>🛒</span>
          <span className="text-left">{itemCount}</span>
          {minutesUntilExpiration && (
            <>
              <span>🕑</span>
              <span className="text-left">{minutesUntilExpiration}</span>
            </>
          )}
        </div>
      </button>
    </div>
  );
}
