import {
  ApiId,
  Dateish,
} from "@museumofoldandnewart/digital-tessitura-client/types";

export const TESSITURA_SESSION_KEY_COOKIE_NAME = "MONTE_CARLO";

export const TESSITURA_SOURCE_ID_COOKIE_NAME = "SOURCE";

export const MONA_FOMA_2022_SEASON_ID: ApiId = 95;

export const MONA_FOMA_2022_FROM_DATE: Dateish = "2022-01-01";

export const DEFAULT_MODE_OF_SALE_ID: ApiId = 50;

export const FERRY_ZONE_MAP_IDS: ApiId[] = [577, 955];

export const JOTFORM_URL_FROM_CONTENTFUL_SLUG: Record<string, string> = {
  "emily-sanzaro": "https://mona.foma.net.au/emily-sanzaro",
  "archive-2022": "https://mona.foma.net.au/ARCHIVE-2022",
  coil: "https://mona.foma.net.au/Coil",
  "faux-mo-laun": "https://mona.foma.net.au/faux-mo-launceston",
  "faux-mo-hob": "https://mona.foma.net.au/faux-mo-hobart",
  "fertile-ground-laun": "https://mona.foma.net.au/fertile-ground-launceston",
  "fertile-ground-hob": "https://mona.foma.net.au/fertile-ground-hobart",
  "mo-hobart": "https://mona.foma.net.au/midnight-oil-hobart",
  "mo-launceston": "https://mona.foma.net.au/midnight-oil-launceston",
  "hobart-mofo-sesh": "https://mona.foma.net.au/mofo-sessions-hobart",
  "launceston-mofo-sesh": "https://mona.foma.net.au/mofo-sessions-launceston",
  "moju-tso": "https://mona.foma.net.au/mo-ju",
  primordial: "https://mona.foma.net.au/primordial",
  "rival-planes-laun": "https://mona.foma.net.au/rival-planes-launceston",
  "rival-planes-hob": "https://mona.foma.net.au/rival-planes-hobart",
  "danny-healy-quartet": "https://mona.foma.net.au/quartet",
  "tuylupa-hobart": "https://mona.foma.net.au/tuylupa-hobart",
  "pakana-kanaplila-tasdance": "https://mona.foma.net.au/tuylupa-launceston",
};
