import { ChangeEvent, useContext } from "react";
import Link from "next/link";
import Img from "next/image";

import { JOTFORM_URL_FROM_CONTENTFUL_SLUG } from "../lib/constants";
import MofoContext from "../context/MofoContext";
import { memeify } from "../lib/memeify";

export function mungeTitleText(text: string) {
  if (!text) {
    return text;
  }

  // " + ": Replace standard space character with thin-space (U+2009) because
  // the standard space renders too spread out in "Space Mono" font
  text = text.replace(/ \+ /g, " + ");

  // " / ": As for " + "
  text = text.replace(/ \/ /g, " / ");

  return text;
}

export function DetailPage({
  children,
  isHobart = true,
}: {
  children?: React.ReactNode;
  isHobart?: boolean;
}) {
  const { lite } = useContext(MofoContext);

  return (
    <div
      className={`flex flex-col items-center justify-center p-6 ${
        lite
          ? ""
          : isHobart
          ? "bg-gradient-to-b from-green via-blue to-orange"
          : "bg-gradient-to-b from-purple via-yellow to-green"
      }`}
    >
      {children}
    </div>
  );
}

export function H1({
  title,
  align = "center",
  className = "",
}: {
  title: string;
  align?: "left" | "center" | "right";
  className?: string;
}) {
  const { lite } = useContext(MofoContext);

  return (
    <h1
      className={`uppercase text-4xl text-${align} ${
        lite ? "" : "font-baseBloom text-5xl"
      } ${className}`}
    >
      {lite ? title : memeify(title)}
    </h1>
  );
}

export function H2({
  title,
  align = "center",
  className = "",
}: {
  title: string;
  align?: "left" | "center" | "right";
  className?: string;
}) {
  const { lite } = useContext(MofoContext);

  return (
    <h2
      className={`uppercase text-4xl text-${align} ${
        lite ? "" : "font-baseBloom text-5xl"
      } ${className}`}
    >
      {lite ? title : memeify(title)}
    </h2>
  );
}

export function H3({
  title,
  align = "center",
  className = "",
}: {
  title: string;
  align?: "left" | "center" | "right";
  className?: string;
}) {
  return (
    <h3 className={`uppercase text-xl text-${align} ${className}`}>{title}</h3>
  );
}

export function HeroImage({ src, altText }: { src: string; altText?: string }) {
  return (
    <div className="my-2">
      <Img
        src={
          src ||
          "https://mofo2022.s3.ap-southeast-2.amazonaws.com/epokhe/mona-epokhe-05.jpeg"
        }
        alt={altText}
        layout="responsive"
        width="250"
        height="250"
      />
    </div>
  );
}

export function Select<T>({
  options,
  value,
  setSelectedOption,
  prompt = "Select an option",
}: {
  options: { id: string; name: string; value: T }[];
  value?: string;
  setSelectedOption: (selectedOption?: T) => void;
  prompt?: string;
}) {
  const PROMPT_PSEUDO_ID = -1;

  const isOnlyAvailableOptionSelected =
    options.length === 1 && options[0].id === value;

  return (
    <select
      disabled={isOnlyAvailableOptionSelected}
      value={value || PROMPT_PSEUDO_ID}
      onChange={({ target }: ChangeEvent<HTMLSelectElement>) => {
        const id = target.value;
        const selectedOption = options.find(
          (option) => option.id === id
        )?.value;
        setSelectedOption(selectedOption);
      }}
      className={`bg-cyan border border-black font-semibold h-10 w-full text-center ${
        isOnlyAvailableOptionSelected ? "appearance-none" : ""
      }`}
    >
      {!isOnlyAvailableOptionSelected && (
        <option key={PROMPT_PSEUDO_ID} value={PROMPT_PSEUDO_ID}>
          {prompt}
        </option>
      )}

      {options.map((option) => {
        return (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        );
      })}
    </select>
  );
}

export function LimitedTicketsMessage({
  available,
  message = `Only ${available} ticket${available > 1 && "s"} remaining`,
  className = "",
}: {
  available: number;
  message?: string;
  className?: string;
}) {
  return (
    <div className={`text-center text-white bg-black py-2 ${className}`}>
      <p>{message}</p>
    </div>
  );
}

export function NoTicketsMessage({
  message = "No tickets available.",
  className = "",
  waitlistSlug,
}: {
  message?: string;
  className?: string;
  waitlistSlug?: string;
}) {
  const jotformUrl = JOTFORM_URL_FROM_CONTENTFUL_SLUG[waitlistSlug];

  return (
    <div className={`text-center text-white bg-black py-2 ${className}`}>
      <p className="flex flex-row gap-2 justify-center">
        {message}
        {jotformUrl?.length > 0 && (
          <Link href={jotformUrl}>
            <a>Join the waitlist</a>
          </Link>
        )}
      </p>
    </div>
  );
}

export function HRLight() {
  return <hr />;
}

export function HRBold() {
  return <hr className="border border-black" />;
}
