import { inspect } from "util";

import { integer } from "@museumofoldandnewart/digital-tessitura-client/types";

/**
 * Return a range of integer values, similar to Python's `range()`
 *
 * Based on
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#sequence_generator_range
 *
 * @param {*} start
 * @param {*} stop
 * @param {*} step
 * @returns
 */
export function range(
  start: integer,
  stop: integer,
  step: integer = 1
): Array<integer> {
  if (stop === undefined) {
    stop = start;
    start = 0;
  }

  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );
}

/**
 * Return an object's value matching the given case-insensitive key.
 *
 * @param {*} obj
 * @param {*} key
 * @returns
 */
export function getByKeyIgnoreCase<T>(
  obj: Record<string, T>,
  key: string
): T | undefined {
  const matchingKey = Object.keys(obj || {}).find(
    (k) => k.toLowerCase() === key.toLowerCase()
  );
  if (matchingKey) {
    return obj[matchingKey];
  }
}

/**
 * Convert an array of objects to a single object that includes each item in
 * the array as a key/value pair, with the key derived from the value of the
 * given field name.
 *
 * Examples:
 *
 *   const arr = [{ id: 1, name: 'foo' }, { id: 2, name: 'bar' }]
 *
 *   arrayToObjectWithFieldName(arr, 'id')
 *   { 1: { id: 1, name: 'foo' }, 2: { id: 2, name: 'bar' } }
 *
 *   arrayToObjectWithFieldName(arr, 'name')
 *   { foo: { id: 1, name: 'foo' }, bar: { id: 2, name: 'bar' } }
 *
 * @param {*} arr Array of objects
 * @param {*} fieldName Name of field to use as key values
 * @param {*} raise If true, throw an error if a key is missing. Default: true
 */
export function arrayToObjectWithFieldName<T>(
  arr: Array<T>,
  fieldName: string,
  {
    raise = true,
    makeList = false,
  }: { raise?: boolean; makeList?: boolean } = {}
): Record<string, T> | Record<string, T[]> {
  const obj = {};

  if (!arr || arr.length === 0) {
    return obj;
  }

  arr.forEach((item) => {
    const keyValue = item[fieldName];

    if (keyValue === undefined && raise) {
      throw new Error(`No value for field '${fieldName}' in item: ${item}`);
    }

    if (makeList) {
      if (!obj[keyValue]) {
        obj[keyValue] = [];
      }
      obj[keyValue].push(item);
    } else {
      obj[keyValue] = item;
    }
  });
  return obj;
}

/**
 * Convert an array of objects to a single object that includes the array's
 * items in the array as a key/value pair, with the key(s) derived by running
 * the given function on an array item.
 *
 * The key name function can return an array of values, in which case the item
 * is given nested keys in the resulting object.
 *
 * Examples:
 *
 *   const arr = [{ id: 1, name: 'foo' }, { id: 2, name: 'bar' }]
 *
 *   arrayToObjectWithFunction(arr, (item) => item.id)
 *   { 1: { id: 1, name: 'foo' }, 2: { id: 2, name: 'bar' } }
 *
 *   arrayToObjectWithFunction(arr, (item) => [item.id, item.name])
 *   {
 *     1: { 'foo': { id: 1, name: 'foo' }},
 *     2: { 'bar': { id: 2, name: 'bar' }},
 *   }
 *
 * @param {*} arr Array of objects
 * @param {*} fn Function that takes a single array item and returns either a
 * key name, or an array of key names, to assign the item in the result object
 * @param {*} raise If true, throw an error if function fails to return key
 * values for a an item. Default: true
 */
export function arrayToObjectWithFunction<T>(
  arr: Array<T>,
  fn: Function,
  { raise = true, makeList = false } = {}
): Record<string, T> | Record<string, T[]> {
  const obj = {};

  if (!arr || arr.length === 0) {
    return obj;
  }

  arr.forEach((item) => {
    const keyValues = fn(item);
    if (keyValues === undefined && raise) {
      throw new Error(
        `No key value from function ${fn} in item: ${inspect(item, {
          depth: 5,
        })}`
      );
    } else if (Array.isArray(keyValues)) {
      let nestedObj = obj;

      while (keyValues.length > 1) {
        const keyValue = keyValues.shift();

        if (!nestedObj[keyValue]) {
          nestedObj[keyValue] = {};
        }

        nestedObj = nestedObj[keyValue];
      }

      if (makeList) {
        if (!nestedObj[keyValues[0]]) {
          nestedObj[keyValues[0]] = [];
        }
        nestedObj[keyValues[0]].push(item);
      } else {
        nestedObj[keyValues[0]] = item;
      }
    } else {
      obj[keyValues] = item;
    }
  });
  return obj;
}
