export const EighteenPlus = ({ colour = "#FFF" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.64 15.64">
    <title>Over 18&apos;s only</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={colour}
          d="M3.35,9.4V5.81H3.23l-.47,1.7H1.87V7.42l.59-2.07H4.29V9.4H5.51v.89H2V9.4Z"
        />
        <path
          fill={colour}
          d="M8,10.39a2.47,2.47,0,0,1-.75-.11A1.55,1.55,0,0,1,6.66,10a1.17,1.17,0,0,1-.36-.44A1.13,1.13,0,0,1,6.17,9V9a1.31,1.31,0,0,1,.07-.46,1.27,1.27,0,0,1,.2-.34,1.26,1.26,0,0,1,.61-.37V7.66a1.14,1.14,0,0,1-.29-.15,1,1,0,0,1-.24-.22A1,1,0,0,1,6.36,7a1.25,1.25,0,0,1-.06-.4v0A1,1,0,0,1,6.44,6a1.32,1.32,0,0,1,.37-.41,1.85,1.85,0,0,1,.53-.25A2.65,2.65,0,0,1,8,5.25a2.51,2.51,0,0,1,.62.08,1.91,1.91,0,0,1,.54.25A1.32,1.32,0,0,1,9.5,6a1.09,1.09,0,0,1,.14.54v0a1.25,1.25,0,0,1-.06.4,1,1,0,0,1-.16.31,1,1,0,0,1-.24.22,1.14,1.14,0,0,1-.29.15v.13a1.26,1.26,0,0,1,.61.37,1.27,1.27,0,0,1,.2.34A1.59,1.59,0,0,1,9.77,9V9a1.2,1.2,0,0,1-.13.57,1.14,1.14,0,0,1-.36.43,1.52,1.52,0,0,1-.57.28A2.45,2.45,0,0,1,8,10.39ZM8,9.5a1,1,0,0,0,.64-.19.61.61,0,0,0,0-1A.94.94,0,0,0,8,8.17a.94.94,0,0,0-.64.19.61.61,0,0,0,0,1A1,1,0,0,0,8,9.5ZM8,6.14a.84.84,0,0,0-.53.16.49.49,0,0,0-.21.41.51.51,0,0,0,.21.41,1,1,0,0,0,1.06,0,.51.51,0,0,0,.21-.41A.49.49,0,0,0,8.5,6.3.84.84,0,0,0,8,6.14Z"
        />
        <path
          fill={colour}
          d="M10.81,7.42h1.08V6.34h.8V7.42h1.07v.8H12.69V9.29h-.8V8.22H10.81Z"
        />
      </g>
    </g>
  </svg>
);

export const Accessible = ({ colour = "#FFF" }) => (
  <svg viewBox="0 0 15.64 15.64">
    <title>Fully accessible</title>
    <clipPath id="a">
      <path d="m0 0h15.64v15.63h-15.64z" />
    </clipPath>
    <path d="m0 0h15.64v15.64h-15.64z" />
    <g clipPath="url(#a)">
      <path
        d="m6.19 3.9.22 2.43h2.47v.79h-2.41l.08.72h3.45l1.57 3.7 1.35-.54.28.64-2 .86-1.63-3.87h-3.75l-.45-5.23a.81.81 0 0 1 -.11-.4.94.94 0 0 1 .93-1 1 1 0 0 1 .95 1 .94.94 0 0 1 -.95.93m4.05 7.47a3.77 3.77 0 1 1 -5.08-4.96l.08.86a3 3 0 1 0 4.52 2.94z"
        fill={colour}
      />
    </g>
  </svg>
);

export const NotSaved = ({ colour = "#000" }) => (
  <svg viewBox="0 0 20 18.9" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m18.83 5.67a8.32 8.32 0 0 1 -1.1 4 19.07 19.07 0 0 1 -2.64 3.54 30.61 30.61 0 0 1 -5.09 4.26 30.15 30.15 0 0 1 -5.08-4.29 17.31 17.31 0 0 1 -2.65-3.54 8.44 8.44 0 0 1 -1.1-4 4.45 4.45 0 0 1 4.47-4.43 4.37 4.37 0 0 1 3.47 1.79l.89 1.18.89-1.18a4.37 4.37 0 0 1 6.21-.84 4.51 4.51 0 0 1 1.73 3.53m-4.4-5.69a5.5 5.5 0 0 0 -4.43 2.24 5.5 5.5 0 0 0 -4.43-2.24 5.63 5.63 0 0 0 -5.57 5.67 9.39 9.39 0 0 0 1.27 4.53 18 18 0 0 0 2.8 3.8 34 34 0 0 0 3.13 2.87c1 .75 1.76 1.33 2.29 1.68l.51.35.51-.35a32.81 32.81 0 0 0 5.42-4.55 17.56 17.56 0 0 0 2.8-3.78 9.39 9.39 0 0 0 1.27-4.55 5.63 5.63 0 0 0 -5.57-5.67"
      fill={colour}
    />
  </svg>
);

export const Saved = ({ colour = "#000" }) => (
  <svg
    viewBox="0 0 20 18.9"
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 mx-auto"
  >
    <path
      d="m14.43 0a5.5 5.5 0 0 0 -4.43 2.24 5.5 5.5 0 0 0 -4.43-2.24 5.63 5.63 0 0 0 -5.57 5.67 9.39 9.39 0 0 0 1.27 4.53 18 18 0 0 0 2.8 3.8 34 34 0 0 0 3.13 2.87c1 .75 1.76 1.33 2.29 1.68l.51.35.51-.35a32.81 32.81 0 0 0 5.42-4.55 17.56 17.56 0 0 0 2.8-3.78 9.39 9.39 0 0 0 1.27-4.55 5.63 5.63 0 0 0 -5.57-5.67"
      fill={colour}
    />
  </svg>
);

export const KidFriendly = ({ colour = "#FFF" }) => (
  <svg viewBox="0 0 15.64 15.64" xmlns="http://www.w3.org/2000/svg">
    <title>Family friendly</title>
    <path d="m0 0h15.64v15.64h-15.64z" />
    <g fill={colour}>
      <path d="m10.88 6.31a.83.83 0 1 0 -1.65 0 .83.83 0 1 0 1.65 0z" />
      <path d="m10 7.62c-1.14 0-1.27.5-1.27 1.07v1.06c0 .32.16.79.54.79l.13 3.1h1.2l.17-3.1c.37 0 .5-.48.5-.79v-1.09c.05-.55-.04-1.04-1.27-1.04z" />
      <path d="m7 2.72a.92.92 0 1 0 -1.84 0 .92.92 0 1 0 1.84 0z" />
      <path d="m6.09 4.19c-1.14 0-1.77.49-1.77 1.07v2.74a.9.9 0 0 0 .75.8l.39 4.8h1.27l.39-4.8a.91.91 0 0 0 .76-.8v-2.78c0-.55-.6-1.03-1.79-1.03z" />
    </g>
  </svg>
);

export const Free = ({ colour = "#FFF" }) => (
  <svg viewBox="0 0 15.64 15.64" xmlns="http://www.w3.org/2000/svg">
    <title>Free Event</title>
    <path d="m0 0h15.64v15.64h-15.64z" />
    <g fill={colour}>
      <path d="m1.25 5.81h2.58v.72h-1.83v.92h1.79v.72h-1.79v1.66h-.75z" />
      <path d="m7.39 9.83h-.76v-1.21a.33.33 0 0 0 -.08-.22.26.26 0 0 0 -.23-.09h-.88v1.52h-.76v-4h1.54a1.55 1.55 0 0 1 .55.08 1.12 1.12 0 0 1 .64.59 1.26 1.26 0 0 1 .08.43v.14a.88.88 0 0 1 -.13.47.91.91 0 0 1 -.36.31v.15a.56.56 0 0 1 .32.15.49.49 0 0 1 .12.34zm-1.95-2.24h.73a.66.66 0 0 0 .42-.13.5.5 0 0 0 .14-.37v-.09a.48.48 0 0 0 -.14-.34.57.57 0 0 0 -.42-.15h-.73z" />
      <path d="m8.26 5.81h2.54v.72h-1.8v.92h1.72v.72h-1.72v.94h1.86v.72h-2.6z" />
      <path d="m11.77 5.81h2.54v.72h-1.78v.92h1.72v.72h-1.72v.94h1.85v.72h-2.61z" />
    </g>
  </svg>
);

export const Ticketed = ({ colour = "#FFF" }) => (
  <svg viewBox="0 0 15.64 15.64" xmlns="http://www.w3.org/2000/svg">
    <title>Ticketed event</title>
    <path d="m0 0h15.64v15.64h-15.64z" />
    <path
      d="m2.61 5v1.38a1.44 1.44 0 0 1 0 2.87v1.43h6.9v-1h.49v1h3v-1.43a1.44 1.44 0 0 1 0-2.87v-1.38h-3v1h-.49v-1zm4.17 1 .48 1.22 1.34.08-1 .83.3 1.25-1.12-.7-1.13.7.35-1.26-1-.83 1.34-.08zm2.73.52h.49v1h-.49zm0 1.57h.49v1h-.49z"
      fill={colour}
    />
  </svg>
);

export const Exclusive = ({ colour = "#FFF" }) => (
  <svg viewBox="0 0 15.64 15.64" xmlns="http://www.w3.org/2000/svg">
    <title>Exclusive to Mona Foma</title>
    <path d="m0 0h15.64v15.64h-15.64z" />
    <g fill={colour}>
      <path d="m1.25 5.81h2.54v.72h-1.79v.92h1.72v.72h-1.72v.94h1.86v.72h-2.61z" />
      <path d="m5.3 5.81.7 1.64h.14l.66-1.64h.82l-.83 2v.1l.83 2h-.82l-.68-1.74h-.12l-.7 1.66h-.82l.83-2v-.1l-.83-1.92z" />
      <path d="m9.58 9.22a.74.74 0 0 0 .36-.07.48.48 0 0 0 .22-.15.76.76 0 0 0 .11-.26 1.53 1.53 0 0 0 0-.3v-.13h.76v.11a1.53 1.53 0 0 1 -.38 1.1 1.46 1.46 0 0 1 -1.1.39 1.44 1.44 0 0 1 -1.09-.42 1.69 1.69 0 0 1 -.4-1.22v-.9a2.09 2.09 0 0 1 .1-.69 1.4 1.4 0 0 1 .3-.52 1.27 1.27 0 0 1 .54-.32 1.56 1.56 0 0 1 .62-.12 1.83 1.83 0 0 1 .64.11 1.31 1.31 0 0 1 .46.3 1.23 1.23 0 0 1 .28.47 2 2 0 0 1 .1.62v.1h-.8v-.1a1.45 1.45 0 0 0 0-.29.85.85 0 0 0 -.11-.26.65.65 0 0 0 -.22-.18.72.72 0 0 0 -.35-.08.66.66 0 0 0 -.32.08.54.54 0 0 0 -.3.19.71.71 0 0 0 -.11.32 1.27 1.27 0 0 0 0 .34v1a1.37 1.37 0 0 0 0 .36.84.84 0 0 0 .11.3.58.58 0 0 0 .23.18.71.71 0 0 0 .35.04z" />
      <path d="m11.8 5.81h.76v3.3h1.83v.72h-2.59z" />
    </g>
  </svg>
);

export const Venue = ({ colour = "#231f20" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.62 15.36">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={colour}
          d="M5.31,0A5.31,5.31,0,0,0,0,5.31,5.89,5.89,0,0,0,.62,7.8,73.66,73.66,0,0,0,4.69,15a.73.73,0,0,0,1.24,0A73.71,73.71,0,0,0,10,7.8a6.54,6.54,0,0,0,.63-2.49A5.26,5.26,0,0,0,5.31,0Zm.56,7.44A2.26,2.26,0,1,1,7.49,5.82,2.25,2.25,0,0,1,5.87,7.44Z"
        />
      </g>
    </g>
  </svg>
);

export const Price = ({ colour = "#231f20" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.42 15.42">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={colour}
          d="M14.69,0H8.91A.74.74,0,0,0,8.4.21L.21,8.4a.74.74,0,0,0,0,1L6,15.21a.76.76,0,0,0,.52.21A.74.74,0,0,0,7,15.21L15.21,7a.74.74,0,0,0,.21-.51V.73A.73.73,0,0,0,14.69,0ZM14,1.46V6.2L6.51,13.66,1.76,8.91,9.22,1.46Z"
        />
        <path fill={colour} d="M12.28,4.1a1,1,0,1,0-1-1A1,1,0,0,0,12.28,4.1Z" />
      </g>
    </g>
  </svg>
);

export const Calendar = ({ colour = "#231f20" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.72 15.72">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cal-1"
          d="M13.74,1.43h-.87V.71a.72.72,0,0,0-1.43,0v.72H8.58V.71a.72.72,0,0,0-1.43,0v.72H4.29V.71a.72.72,0,0,0-1.43,0v.72H2a2,2,0,0,0-2,2V13.74a2,2,0,0,0,2,2H13.74a2,2,0,0,0,2-2V3.42A2,2,0,0,0,13.74,1.43Zm.56,12.31a.56.56,0,0,1-.56.56H2a.56.56,0,0,1-.56-.56V7.15H14.3Zm0-8H1.43V3.42A.56.56,0,0,1,2,2.86h.87v.71a.72.72,0,1,0,1.43,0V2.86H7.15v.71a.72.72,0,1,0,1.43,0V2.86h2.86v.71a.72.72,0,1,0,1.43,0V2.86h.87a.56.56,0,0,1,.56.56Z"
        />
        <path
          className="cal-1"
          d="M3.3,9.57a.75.75,0,0,0,.07.12.76.76,0,0,0,.08.11l.11.09.13.06.13,0a.66.66,0,0,0,.28,0l.13,0,.13-.06a.9.9,0,0,0,.19-.2.75.75,0,0,0,.07-.12.74.74,0,0,0,0-.14.61.61,0,0,0,0-.14.72.72,0,0,0-.31-.59l-.13-.07-.13,0a.72.72,0,0,0-.84.56.71.71,0,0,0,0,.14.61.61,0,0,0,0,.14A.74.74,0,0,0,3.3,9.57Z"
        />
        <path
          className="cal-1"
          d="M7.86,10,8,10l.14,0,.12-.06.11-.09a.71.71,0,0,0,0-1h0a.31.31,0,0,0-.11-.09l-.12-.07L8,8.59A.71.71,0,0,0,7.72,10Z"
        />
        <path
          className="cal-1"
          d="M11.11,9.57a.67.67,0,0,0,.06.12.76.76,0,0,0,.2.2l.12.06.14,0a.61.61,0,0,0,.27,0L12,10l.12-.06a.73.73,0,0,0,.32-.6.71.71,0,0,0,0-.14l0-.13-.07-.13a.73.73,0,0,0-.2-.19L12,8.63l-.14,0a.61.61,0,0,0-.27,0l-.14,0-.12.07a.73.73,0,0,0-.2.19.75.75,0,0,0-.06.13.69.69,0,0,0,0,.13,1.31,1.31,0,0,0,0,.28A.78.78,0,0,0,11.11,9.57Z"
        />
        <path
          className="cal-1"
          d="M4,12.87l.14,0,.13,0,.13-.06a.75.75,0,0,0,.31-.6.66.66,0,0,0,0-.14.65.65,0,0,0,0-.13.54.54,0,0,0-.07-.13.86.86,0,0,0-.19-.19l-.13-.07-.13,0a.66.66,0,0,0-.28,0l-.13,0-.13.07a.44.44,0,0,0-.11.09l-.08.1a.54.54,0,0,0-.07.13.65.65,0,0,0,0,.13.66.66,0,0,0,0,.14A.71.71,0,0,0,4,12.87Z"
        />
        <path
          className="cal-1"
          d="M7.2,12.42a.54.54,0,0,0,.07.13.7.7,0,0,0,.59.32l.14,0,.14,0,.12-.06.11-.09.09-.11.06-.13a.5.5,0,0,0,0-.13.68.68,0,0,0,0-.14.72.72,0,0,0-1.43,0,.66.66,0,0,0,0,.14A.5.5,0,0,0,7.2,12.42Z"
        />
        <path
          className="cal-1"
          d="M11.11,12.42a.52.52,0,0,0,.06.13.76.76,0,0,0,.2.2l.12.06.14,0a.62.62,0,0,0,.27,0l.14,0,.12-.06a.76.76,0,0,0,.2-.2s.05-.08.07-.13,0-.08,0-.13a1.31,1.31,0,0,0,0-.28l0-.13c0-.05,0-.09-.07-.13a.73.73,0,0,0-.2-.19L12,11.49l-.14,0a.62.62,0,0,0-.27,0l-.14,0-.12.07a.73.73,0,0,0-.2.19.52.52,0,0,0-.06.13.69.69,0,0,0,0,.13,1.31,1.31,0,0,0,0,.28A.5.5,0,0,0,11.11,12.42Z"
        />
      </g>
    </g>
  </svg>
);

export const Search = ({ colour = "#000" }) => (
  <svg viewBox="0 0 40 40">
    <title>Search</title>
    <g>
      <path d="M4.1,14.5C4.1,8.7,8.8,4,14.5,4l0,0C8.7,4,4,8.7,4.1,14.5C4,20.3,8.8,25,14.6,25C8.8,25,4.1,20.3,4.1,14.5z" />
      <path d="M14.6,25c5.8,0,10.5-4.7,10.5-10.5C25.1,20.3,20.4,25,14.6,25z" />
      <path
        d="M26.1,23.2c1.9-2.4,3-5.5,3-8.8c0-8-6.5-14.5-14.5-14.5S0,6.4,0,14.5C0,22.5,6.5,29,14.5,29c3.3,0,6.3-1.1,8.7-2.9L37.1,40
		l2.9-2.9L26.1,23.2z M14.6,25C8.8,25,4,20.3,4,14.5S8.7,4,14.5,4l0,0l0,0C20.3,4,25,8.7,25,14.5C25.1,20.3,20.4,25,14.6,25z"
      />
      <path d="M14.6,4L14.6,4c5.7,0,10.5,4.7,10.5,10.5C25.1,8.7,20.4,4,14.6,4z" />
    </g>
  </svg>
);

export const UpArrow = ({ colour = "#000" }) => (
  <svg viewBox="0 0 32 32.71" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke={colour} strokeLinecap="round" strokeWidth="2">
      <path d="m1 13.71 15-12.71 15 12.71" strokeLinejoin="round" />
      <path d="m16 31.71v-30" strokeMiterlimit="10" />
    </g>
  </svg>
);

export const Auslan = ({ colour = "#FFF" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.64 15.64">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect width="15.64" height="15.64" />
        <path
          fill={colour}
          d="M1.8,10.48a3.31,3.31,0,0,1-.52-1.26,4.5,4.5,0,0,1-.06-1.51.23.23,0,0,1,.09-.14,1.63,1.63,0,0,0,.91-1.1,11,11,0,0,1,.57-1.55,8,8,0,0,1,1.3-1.86,8.7,8.7,0,0,0,.68-.8l.12-.09A.37.37,0,0,1,5,2.75c-.31.39-.61.78-.91,1.18A.44.44,0,0,0,4,4.08l0,0L4.2,4A4.7,4.7,0,0,1,5.91,3,6.18,6.18,0,0,1,7,2.82a.28.28,0,0,1,.22.11.31.31,0,0,1,0,.25.66.66,0,0,1-.3.2c-.42.15-.85.27-1.28.4a.59.59,0,0,0-.14.06l-.9.74a.15.15,0,0,0,0,.08l.31-.13a5.67,5.67,0,0,1,1-.33,4.38,4.38,0,0,1,1,0,.49.49,0,0,1,.23.13L7.88,5c-.15.35-.37.43-.67.24C7.07,5.15,7,5,6.8,4.93a.67.67,0,0,0-.24-.07l-.36,0a1,1,0,0,0-.77.24c-.21.17-.43.32-.63.49a.39.39,0,0,0-.11.21,2.52,2.52,0,0,1,1.42-.25.65.65,0,0,1,.34.13c.31.21.6.43.89.66a.45.45,0,0,1,.16.29,5.51,5.51,0,0,1,0,.81c0,.09-.06.18,0,.27a.16.16,0,0,1,0,.12c-.18.42-.34.85-.53,1.26a.46.46,0,0,1-.26.18l-2,.67A1.19,1.19,0,0,1,4,10,2.6,2.6,0,0,0,2,10.36Zm4.57-2a.83.83,0,0,0,.16-.13l.53-.64c.06-.07.07-.11,0-.19s-.19-.4-.3-.6a.44.44,0,0,0-.15-.18l-.55-.34a.2.2,0,0,0-.22,0,2.31,2.31,0,0,1-.51.22,1,1,0,0,0-.82.85.42.42,0,0,0,.12.44l.53.61a.16.16,0,0,0,.14.05A1.85,1.85,0,0,1,6.37,8.5Z"
        />
        <path
          fill={colour}
          className="auslan"
          d="M10.78,13.47c-.27-.22-.28-.42-.1-.64s.6-.76.89-1.14a.48.48,0,0,0,.08-.16l0,0-.16.1a4.77,4.77,0,0,1-1.62,1,7.69,7.69,0,0,1-1.18.23.25.25,0,0,1-.21-.11.29.29,0,0,1,0-.24.8.8,0,0,1,.33-.22A9.26,9.26,0,0,1,9.7,12a1.92,1.92,0,0,0,.81-.48,5,5,0,0,1,.62-.49l0,0c-.12.05-.24.11-.36.15a5.38,5.38,0,0,1-1,.32,4.75,4.75,0,0,1-1-.06.42.42,0,0,1-.22-.13c-.23-.17-.45-.36-.68-.54s-.08-.15,0-.23a.36.36,0,0,1,.53-.12,5.88,5.88,0,0,1,.51.36.28.28,0,0,0,.15.06,4.91,4.91,0,0,0,.66-.06.81.81,0,0,0,.37-.12c.27-.18.53-.39.79-.59a.69.69,0,0,0,.13-.2,5.51,5.51,0,0,1-.77.21,4.41,4.41,0,0,1-.73,0,.36.36,0,0,1-.2-.08c-.35-.26-.7-.51-1-.79a.33.33,0,0,1-.11-.25c0-.28,0-.56.07-.84,0-.05,0-.12,0-.13-.16-.12-.07-.25,0-.37s.2-.52.3-.78a.82.82,0,0,1,.56-.54c.62-.17,1.23-.39,1.84-.59a1.37,1.37,0,0,1,.78,0,2.51,2.51,0,0,0,1.78-.27l.39-.22A4.19,4.19,0,0,1,14.41,8a1.71,1.71,0,0,0-1,1.27,7.76,7.76,0,0,1-.54,1.44,7.51,7.51,0,0,1-1.17,1.69c-.27.27-.51.59-.76.89A1,1,0,0,0,10.78,13.47ZM8.55,8c.1.23.2.48.32.73A.33.33,0,0,0,9,8.84c.19.12.38.22.57.35a.18.18,0,0,0,.21,0c.21-.09.43-.18.65-.25a.83.83,0,0,0,.6-.59,2,2,0,0,1,.08-.26A.34.34,0,0,0,11,7.74c-.2-.22-.39-.45-.58-.67a.15.15,0,0,0-.13,0,2.13,2.13,0,0,1-1,.09.27.27,0,0,0-.2.09C8.92,7.47,8.73,7.73,8.55,8Z"
        />
      </g>
    </g>
  </svg>
);

export const AudioDescription = ({ colour1 = "#FFF", colour2 = "#231f20" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.64 15.64">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect fill={colour1} x="0.21" y="0.22" width="15.22" height="15.22" />
        <path d="M0,0V15.64H15.64V0ZM13.23,12.39a1.55,1.55,0,0,1-1,.43H3.34a1.14,1.14,0,0,1-.69-.31A6.07,6.07,0,0,1,1,7.79S1,4.26,2.62,3.05a1.83,1.83,0,0,1,.91-.33l8.78-.05a1.59,1.59,0,0,1,.89.45,6.35,6.35,0,0,1,1.51,4.11C14.92,10.21,13.81,11.89,13.23,12.39Z" />
        <path d="M10.46,5.65h.23a4.22,4.22,0,0,1,.81,2,3.19,3.19,0,0,1-.79,2.31h-.25a4.17,4.17,0,0,0,.7-2.19A4.22,4.22,0,0,0,10.46,5.65Z" />
        <path d="M11.41,5.65a3.77,3.77,0,0,1,.72,2.14,4,4,0,0,1-.72,2.15h.25a3.21,3.21,0,0,0,.8-2,3.35,3.35,0,0,0-.8-2.28Z" />
        <path d="M12.38,5.65h.23a3.62,3.62,0,0,1,.79,2.14,3.16,3.16,0,0,1-.77,2.15h-.27a3.62,3.62,0,0,0,.72-2.16A4.6,4.6,0,0,0,12.38,5.65Z" />
        <path d="M5.22,5.55,2,10h1.6L4,9.46H5.45l0,.57H6.56V5.55ZM4.59,8.46l.9-1.41,0,1.41Z" />
        <path d="M8.61,5.55H6.85V10H8.6a2.26,2.26,0,0,0,1.9-2.25A2.36,2.36,0,0,0,8.61,5.55ZM7.89,8.87l0-2.16s1.3-.06,1.3,1A1.12,1.12,0,0,1,7.89,8.87Z" />
        <path
          fill={colour2}
          d="M0,0V15.64H15.64V0ZM13.23,12.39a1.55,1.55,0,0,1-1,.43H3.34a1.14,1.14,0,0,1-.69-.31A6.07,6.07,0,0,1,1,7.79S1,4.26,2.62,3.05a1.83,1.83,0,0,1,.91-.33l8.78-.05a1.59,1.59,0,0,1,.89.45,6.35,6.35,0,0,1,1.51,4.11C14.92,10.21,13.81,11.89,13.23,12.39Z"
        />
        <path
          fill={colour2}
          d="M10.46,5.65h.23a4.22,4.22,0,0,1,.81,2,3.19,3.19,0,0,1-.79,2.31h-.25a4.17,4.17,0,0,0,.7-2.19A4.22,4.22,0,0,0,10.46,5.65Z"
        />
        <path
          fill={colour2}
          d="M11.41,5.65a3.77,3.77,0,0,1,.72,2.14,4,4,0,0,1-.72,2.15h.25a3.21,3.21,0,0,0,.8-2,3.35,3.35,0,0,0-.8-2.28Z"
        />
        <path
          fill={colour2}
          d="M12.38,5.65h.23a3.62,3.62,0,0,1,.79,2.14,3.16,3.16,0,0,1-.77,2.15h-.27a3.62,3.62,0,0,0,.72-2.16A4.6,4.6,0,0,0,12.38,5.65Z"
        />
        <path
          fill={colour2}
          d="M5.22,5.55,2,10h1.6L4,9.46H5.45l0,.57H6.56V5.55ZM4.59,8.46l.9-1.41,0,1.41Z"
        />
        <path
          fill={colour2}
          d="M8.61,5.55H6.85V10H8.6a2.26,2.26,0,0,0,1.9-2.25A2.36,2.36,0,0,0,8.61,5.55ZM7.89,8.87l0-2.16s1.3-.06,1.3,1A1.12,1.12,0,0,1,7.89,8.87Z"
        />
      </g>
    </g>
  </svg>
);

export const Tactile = ({ colour1 = "#FFF", colour2 = "#231f20" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.63 15.64">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect fill={colour1} x="0.19" y="0.2" width="15.26" height="15.26" />
        <path d="M0,0V15.64H15.63V0ZM5.39,1.38A1.12,1.12,0,1,1,4.27,2.5,1.12,1.12,0,0,1,5.39,1.38ZM7.81,14.12a.75.75,0,0,1-1.06-.3h0a.64.64,0,0,1-.06-.18L5.8,11.12,6.46,9.3s.68,1.84,1.3,3.22C8,13,8.33,13.82,7.81,14.12Zm.53-5.61L5.7,5.37s-.12-.19-.2-.1,0,.15,0,.15l.87,1V9s-.61,1.8-1.16,3.28a5.58,5.58,0,0,1-.85,1.91.8.8,0,0,1-.75,0,.71.71,0,0,1-.36-.72C3.3,13.32,4.7,9,4.7,9l0-3L4.13,7.19l.25,1.6s.13.59-.42.68S3.37,9,3.38,9L3,6.82,4.56,4.09h0c.11-.18.37-.25.81-.25a.89.89,0,0,1,.76.29h0L9.18,7.87a.29.29,0,0,1,.1.12l0,0h0a.46.46,0,0,1,0,.21C9.35,9,8.52,8.83,8.34,8.51ZM12.57,14c-.14.09-.18-.17-.33-.4-.81-1.24-3-4.75-3-4.75s0,0,.12,0a.17.17,0,0,0,.07-.12s2.22,3.61,3,4.86C12.52,13.79,12.65,14,12.57,14Z" />
        <path
          fill={colour2}
          d="M0,0V15.64H15.63V0ZM5.39,1.38A1.12,1.12,0,1,1,4.27,2.5,1.12,1.12,0,0,1,5.39,1.38ZM7.81,14.12a.75.75,0,0,1-1.06-.3h0a.64.64,0,0,1-.06-.18L5.8,11.12,6.46,9.3s.68,1.84,1.3,3.22C8,13,8.33,13.82,7.81,14.12Zm.53-5.61L5.7,5.37s-.12-.19-.2-.1,0,.15,0,.15l.87,1V9s-.61,1.8-1.16,3.28a5.58,5.58,0,0,1-.85,1.91.8.8,0,0,1-.75,0,.71.71,0,0,1-.36-.72C3.3,13.32,4.7,9,4.7,9l0-3L4.13,7.19l.25,1.6s.13.59-.42.68S3.37,9,3.38,9L3,6.82,4.56,4.09h0c.11-.18.37-.25.81-.25a.89.89,0,0,1,.76.29h0L9.18,7.87a.29.29,0,0,1,.1.12l0,0h0a.46.46,0,0,1,0,.21C9.35,9,8.52,8.83,8.34,8.51ZM12.57,14c-.14.09-.18-.17-.33-.4-.81-1.24-3-4.75-3-4.75s0,0,.12,0a.17.17,0,0,0,.07-.12s2.22,3.61,3,4.86C12.52,13.79,12.65,14,12.57,14Z"
        />
      </g>
    </g>
  </svg>
);