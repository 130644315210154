import Link from "next/link";
import { useContext } from "react";
import MofoContext from "../context/MofoContext";

const Button = ({
  buttonText,
  width = "w-64",
  onClick = undefined,
  href = undefined,
  external = false,
  disabled = false,
  arrow = false,
  hideArrow = false,
  type = "button",
  className = "",
}) => {
  const { isTicketsPage } = useContext(MofoContext);

  const hoverClasses =
    " has-hover:hover:bg-black " +
    (isTicketsPage
      ? "has-hover:hover:border-cyan has-hover:hover:text-cyan"
      : "has-hover:hover:border-yellow has-hover:hover:text-yellow");
  const styleClasses = `p-3 px-4 leading-none border border-black text-black ${
    isTicketsPage ? "bg-cyan" : "bg-yellow"
  } ${width} uppercase transition-all duration-500 block text-center no-underline`;
  const disabledOverrides = "disabled:opacity-40 disabled:cursor-default";
  const classes = [
    styleClasses,
    disabled ? disabledOverrides : hoverClasses,
    className,
  ].join(" ");

  const showArrow = arrow || (href && !hideArrow);

  return href ? (
    <Link href={href}>
      <a className={classes} target={`${external ? "_blank" : "_self"}`}>
        {buttonText} {showArrow ? "→" : ""}
      </a>
    </Link>
  ) : (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {buttonText} {showArrow ? "→" : ""}
    </button>
  );
};

export default Button;
