import Link from "next/link";
import Img from "next/image";
import { useContext, useEffect, useState } from "react";

import { MofoContext } from "../context/MofoContext";
import { UpArrow, Venue } from "../components/Icons";
import { FilteredListing } from "../components/FilteredListing";
import Button from "../components/Button";
import { getProgram } from "./api/program";
import ProgramIcons from "../components/ProgramIcons";
import { memeify } from "../lib/memeify";
import FavouriteButton from "../components/FavouriteButton";
import ProductionSeasonPrice from "../components/ProductionSeasonPrice";
import ProductionSeasonDates from "../components/ProductionSeasonDates";
import FullScreenBackground from "../components/FullScreenBackground";
import Meta from "../components/Meta";
import {
  FilteredItem,
  MF22ContentfulProgram,
  MF22ContentfulProductionSeason,
} from "../lib/types";
import { GetSponsors } from "./api/sponsors";

import getConfig from "next/config";
import { analyticsGotoTopPressed } from "../lib/analyticsTrackingFunctions";
const { publicRuntimeConfig } = getConfig();

const PLACEHOLDER_IMAGE_SRC =
  "https://mofo2022.s3.ap-southeast-2.amazonaws.com/epokhe/mona-epokhe-05.jpeg";

type FilteredProductionSeason = MF22ContentfulProductionSeason & FilteredItem;

export default function Program({
  program,
}: {
  program: MF22ContentfulProgram;
}) {
  // This component displays the filtered items from `<FilteredListing />`
  const FilteredItemsComponent = ({
    filteredItems,
    isDateMode,
  }: {
    filteredItems: FilteredProductionSeason[];
    isDateMode: boolean;
  }) => {
    return (
      <>
        <div className="w-lg max-w-full relative p-2 md:p-4 flex flex-wrap justify-center">
          {filteredItems?.map((event, i) => {
            return isDateMode ? (
              <DateProgram key={event.slug} event={event} position={i} />
            ) : (
              <NonDateProgram key={event.slug} event={event} />
            );
          })}
        </div>
      </>
    );
  };

  const [showGotoTop, setShowGotoTop] = useState(false);

  useEffect(() => {
    window.onscroll = throttle(function () {
      setShowGotoTop(
        document.documentElement.scrollTop > window.innerHeight / 2
      );
    }, 200);
  });

  function throttle(fn, wait) {
    var time = Date.now();
    return function () {
      if (time + wait - Date.now() < 0) {
        fn();
        time = Date.now();
      }
    };
  }

  return (
    <>
      <Meta
        title="Program"
        description="Launceston 21–23 January. nipaluna / Hobart 28–30 January. Plenty of free/fun stuff. Come and play."
        image="https://mofo2022.s3.ap-southeast-2.amazonaws.com/social/MF22-PA-share.jpg"
      ></Meta>

      <main>
        <FullScreenBackground />
        <FilteredListing
          items={program.productionSeasons}
          FilteredItemsComponent={FilteredItemsComponent}
        />
        <div
          className={`w-10 h-10 fixed bottom-4 right-4 bg-pink p-2 border border-black rounded-full ${
            showGotoTop ? "" : "hidden"
          } items-center justify-center sm:hidden cursor-pointer`}
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
            analyticsGotoTopPressed();
          }}
        >
          <UpArrow />
        </div>
      </main>
    </>
  );
}

export async function getStaticProps() {
  const program = (await getProgram()) || {};
  const sponsors = await GetSponsors();

  return {
    props: { program, sponsors },
  };
}

const NonDateProgram = ({ event }) => {
  const { lite } = useContext(MofoContext);

  const hoverClasses = `has-hover:hover:bg-black
    ${lite ? "has-hover:hover:text-white" : "has-hover:hover:text-yellow"}`;
  const style = "group w-full text-center my-2 p-4 transition-all duration-300";

  return (
    <div className={`${style} ${hoverClasses}`}>
      <Link href="#">
        <a className="no-underline">
          <h2 className="text-3xl has-hover:group-hover:font-sans">
            {event.title}
          </h2>
        </a>
      </Link>
    </div>
  );
};

const DateProgram = ({
  event,
  position,
}: {
  event: FilteredProductionSeason;
  position: number;
}) => {
  const { lite } = useContext(MofoContext);

  const ticketsPath = event?.ticketUrlExtra?.replace(/^\/*/, "") || "";
  const ticketsUrl = ticketsPath
    ? ["https://buy.monafoma.net.au", ticketsPath].join("/")
    : "";

  return (
    <div
      key={event.title}
      className={`border border-black m-4 p-4 max-w-md w-full flex flex-wrap bg-yellow`}
    >
      <div>
        {event.pretitleExtra && (
          <h3 className="text-sm w-full font-normal">{event.pretitleExtra}</h3>
        )}
        <Link href={`/program/${event.slug}`} passHref>
          <a className="no-underline">
            <h2
              className={`text-3xl w-full ${
                lite ? "" : "font-baseBloom text-4xl"
              }`}
            >
              {lite
                ? event.titleOverride || event.title
                : memeify(event.titleOverride || event.title)}
            </h2>
          </a>
        </Link>
        {event.subtitleExtra && (
          <h2 className="text-xl w-full font-normal">{event.subtitleExtra}</h2>
        )}
      </div>
      <div className="flex w-full my-2 flex-wrap sm:flex-nowrap">
        <div className="sm:w-1/2 pr-4 w-48 h-48 sm:h-auto">
          <Link href={`/program/${event.slug}`} passHref>
            <a>
              <Img
                src={event.imageUrlExtra || PLACEHOLDER_IMAGE_SRC}
                className=""
                alt={event?.imageAltTextExtra}
                layout="responsive"
                width="250"
                height="250"
              />
            </a>
          </Link>
        </div>
        <div className="w-full mt-4 sm:w-1/2 sm:mt-0">
          <ProductionSeasonDates productionSeason={event} />

          <ProductionSeasonPrice
            productionSeason={event}
            override={
              event.pretitleExtra === "Mofo Sessions" &&
              "Included in Mofo Sessions ticket"
            }
          />

          {event?.facilities?.[0]?.title && (
            <div className="flex">
              <div className="w-4 flex-shrink-0 mr-2">
                <Venue />
              </div>
              {event?.facilities?.[0]?.showOnMapExtra ? (
                <Link href={`/map/${event.facilities?.[0]?.slug}`} passHref>
                  <a>
                    <p>
                      {event?.facilities?.[0]?.titleOverride ||
                        event?.facilities?.[0]?.title}
                      {event?.facilities?.[0]?.addressExtra
                        ? `, ${event.facilities?.[0].addressExtra}`
                        : ""}
                    </p>
                  </a>
                </Link>
              ) : (
                <p>
                  {event?.facilities?.[0]?.titleOverride ||
                    event?.facilities?.[0]?.title}
                  {event?.facilities?.[0]?.addressExtra
                    ? `, ${event.facilities?.[0].addressExtra}`
                    : ""}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full">
        <Button
          buttonText="More info"
          width="w-full sm:w-1/2 my-4 self-start"
          href={`/program/${event.slug}`}
          hideArrow={true}
        />
        {publicRuntimeConfig.showTicketsOnProgram && ticketsUrl && (
          <Button
            buttonText="Tickets"
            width="w-full sm:w-1/2 ml-4 my-4 self-start"
            href={ticketsUrl}
            hideArrow={true}
          />
        )}
      </div>
      <div className="flex w-full justify-between flex-col sm:flex-row items-center">
        <ProgramIcons event={event} className="my-4" />
        <FavouriteButton event={event} position={position} />
      </div>
    </div>
  );
};
