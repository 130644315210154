import type { NextApiRequest, NextApiResponse } from "next";
import getConfig from "next/config";
import fetch from "node-fetch";
import { RequestInfo, RequestInit, BodyInit } from "node-fetch";
import { AbortController } from "node-abort-controller";
import Cookies from "cookies";

import {
  ApiId,
  integer,
  WebSessionKey,
} from "@museumofoldandnewart/digital-tessitura-client/types";

import {
  TESSITURA_SESSION_KEY_COOKIE_NAME,
  TESSITURA_SOURCE_ID_COOKIE_NAME,
} from "./constants";
import { BetterError } from "./errors";

const { publicRuntimeConfig } = getConfig();

export interface BetterFetchRequestInit extends Omit<RequestInit, "body"> {
  timeout?: integer;
  parseFunctionName?: string;
  body?: string | Object | BodyInit;
}

export class FetchError extends BetterError {}

/**
 * An improved version of the `fetch` HTTP/S client function with request
 * timeouts and automatic handling of JSON-bearing interactions.
 *
 * Returns the parsed response data from a request or raises a `FetchError`.
 *
 * Accepts the standard `(url, options)` arguments as the normal `fetch` but
 * supports some extras and does special handling of others...
 *
 * Extra options:
 *
 * - `timeout`: The request timeout in milliseconds (optional). Default 60000
 *   (60 seconds).
 * - `parseFunctionName`: Name of the function to parse the response object:
 *   `json`, `text`, `formData`, `blob`, or `arrayBuffer` (optional). Default
 *   is `json`.
 *
 * Options with special handling:
 *
 * - `headers`: Headers for request (optional). Default is "Content-Type" and
 *   "Accept" headers to "application/json".
 * - `body`: Request body payload (optional). Automatically serialized as JSON
 *   if it is an object and the "Content-Type" is "application/json".
 * - `params`: GET parameters object (optional). Convert an object to
 *   encoded GET parameters and append them to the URL.
 * - `method`: HTTP method for request (optional). Default is "POST" if `body`
 *   is provided, otherwise "GET".
 *
 *
 * A `FetchError` is raised if the request fails due to:
 *
 * - an unexpected response status like a 400 client error or 500 server error.
 *   In this case the error object includes the field `info.response` with the
 *   full response object.
 * - a low-level unrecoverable failure with networking, timeout etc. In this
 *   case the error object includes the fields `cause` with the original error
 *   and `info.response` with the response object.
 *
 * @param {string} url
 * @param {*} options
 * @param {*} initialData Default data to return until request completes
 * (optional).
 * @param {string} responseDataFunctionName Name of the function to call on the
 * `fetch` response object to read result data: `json`, `text`, `formData`,
 * `blob`, `arrayBuffer` (optiona). Default is `json`.
 */
export async function betterFetch(
  url: RequestInfo,
  options: BetterFetchRequestInit = {}
): Promise<any> {
  // Apply a timeout to fetch requests, 60 seconds by default
  const { timeout = 60000 } = options;
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  // JSON-friendly headers by default
  const {
    headers = {
      "Content-Type": "application/json",
      Accepts: "application/json",
    },
  } = options;

  // Automatically serialize body as JSON if necessary for a JSON-bearing request
  let { body } = options;
  if (body && typeof body !== "string") {
    body = JSON.stringify(body);
  }

  // Convert `params` object option to URL-encoded GET parameters
  const { params } = options;
  if (params) {
    const encodedParams = Object.keys(params)
      .reduce((list, name) => {
        const value = params[name];
        if (value !== undefined) {
          list.push(`${name}=${encodeURIComponent(value)}`);
        }
        return list;
      }, [])
      .join("&");
    url += url.indexOf("?") >= 0 ? "&" : "?";
    url += encodedParams;
  }

  // Extract key options for improved error messages
  const { method = body ? "POST" : "GET" } = options;

  // Name of function called on response to parse its data payload
  const { parseFunctionName = "json" } = options;

  let response;
  try {
    response = await fetch(url, {
      // Apply request timeout with signal
      signal: controller.signal,
      // Pass through all given options
      ...options,
      // Apply default options and overrides
      method,
      headers,
      body: body as string,
    });

    // Clear request timeout as soon as we get a response: we want the timeout
    // to apply to the request phase only, not the response reading phase
    clearTimeout(timeoutId);

    // Parse error response body, whether or not the response is considered OK
    let responseData;
    let responseDataError;
    try {
      responseData = await response[parseFunctionName]();
    } catch (errorParsingErrorResponseData) {
      responseDataError = errorParsingErrorResponseData;
    }

    // Raise error if response is not a success, and include the `response`
    // object with the error in case caller can extract useful info from it
    if (!response.ok) {
      throw new FetchError(
        `Fetch invalid response for ${method} '${url}': ` +
          `${response.status} ${response.statusText}`,
        {
          info: {
            status: response.status,
            statusText: response.statusText,
            response,
            data: responseData,
          },
        }
      );
    }

    // Return parsed response data if we have it, raise the parsing error if we don't
    if (responseDataError) {
      throw responseDataError;
    } else {
      return responseData;
    }
  } catch (error) {
    if (error instanceof FetchError) {
      throw error;
    }

    throw new FetchError(`Fetch failed for ${method} '${url}': ${error}`, {
      cause: error,
      info: { response: response },
    });
  } finally {
    // Clear request timeout if it was not cleared by now
    clearTimeout(timeoutId);
  }
}

export function decomposeHost(host: string) {
  let [domain, port] = (Array.isArray(host) ? host[0] : host || "").split(":");

  const isDevHost = domain.includes("localhost") || domain.includes(".mine");
  const isSecureConnection = !isDevHost;
  const rootDomain = domain
    .split(".")
    .filter(
      (part) => !publicRuntimeConfig.ticketsSubdomainsSupported.includes(part)
    )
    .join(".");

  return { domain, port, isSecureConnection, rootDomain };
}

export class SessionKeyCookieManager {
  readonly cookies: Cookies;
  readonly isSecureConnection: boolean;
  readonly rootDomain: string;
  _updatedSessionKey: WebSessionKey | undefined = undefined;

  constructor(req: NextApiRequest, res: NextApiResponse) {
    const host = req.headers["x-forwarded-host"] || req.headers["host"];

    const { isSecureConnection, rootDomain } = decomposeHost(host as string);

    this.isSecureConnection = isSecureConnection;
    this.rootDomain = rootDomain;

    // Set `secure` flag so we can set secure cookies on Vercel or other hosts
    // behind a proxy, which `Cookies` will otherwise consider insecure.
    this.cookies = new Cookies(req, res, { secure: this.isSecureConnection });
  }

  get sessionKey(): WebSessionKey | undefined {
    // Return updated session key if we have one, instead of browser-provided one
    if (this._updatedSessionKey) {
      return this._updatedSessionKey;
    }

    return this.cookies.get(TESSITURA_SESSION_KEY_COOKIE_NAME);
  }

  set sessionKey(sessionKey: WebSessionKey | undefined) {
    // Remember an updated session key so we can return it via getter
    this._updatedSessionKey = sessionKey;

    this.cookies.set(TESSITURA_SESSION_KEY_COOKIE_NAME, sessionKey, {
      httpOnly: true,
      domain: this.rootDomain,
      secure: this.isSecureConnection,
    });
  }

  deleteSessionKey() {
    this.sessionKey = undefined;
  }
}

export class SourceIdCookieManager {
  readonly cookies: Cookies;
  readonly rootDomain: string;
  _updatedSourceId: ApiId | undefined = undefined;

  constructor(req: NextApiRequest, res: NextApiResponse) {
    const host = req.headers["x-forwarded-host"] || req.headers["host"];

    const { rootDomain } = decomposeHost(host as string);

    this.rootDomain = rootDomain;

    this.cookies = new Cookies(req, res);
  }

  get sourceId(): ApiId | undefined {
    // Return updated value if we have one, instead of browser-provided one
    if (this._updatedSourceId) {
      return this._updatedSourceId;
    }

    return this.cookies.get(TESSITURA_SOURCE_ID_COOKIE_NAME);
  }

  set sourceId(sourceId: ApiId | undefined) {
    // Remember an updated value so we can return it via getter
    this._updatedSourceId = sourceId;

    this.cookies.set(TESSITURA_SOURCE_ID_COOKIE_NAME, sourceId, {
      httpOnly: true,
      domain: this.rootDomain,
    });
  }

  deleteSourceId() {
    this.sourceId = undefined;
  }
}
