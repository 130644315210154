import React, { createContext, useState, useEffect } from "react";
import { useQuery } from "react-query";

import {
  getCart,
  getWebSessionWithExpirations,
  useSendErrorToSentryEffect,
} from "../lib/hooks";
import { CartWithFacilities, WebSessionWithExpirations } from "../lib/types";

type CartContext = {
  siteError?: Error;
  setSiteError: React.Dispatch<React.SetStateAction<Error | undefined>>;
  webCart?: CartWithFacilities;
  webSessionWithExpirations?: WebSessionWithExpirations;
  refreshWebCart: () => Promise<void>;
  isLoadingCart: boolean;
};

export const TessituraCartContext = createContext<CartContext>(null);

export const TessituraCartContextProvider = ({ children }) => {
  const [siteError, setSiteError] = useState<Error>();
  const [webSessionWithExpirations, setWebSessionWithExpirations] =
    useState<WebSessionWithExpirations>(null);
  const [webCart, setWebCart] = useState<CartWithFacilities>(null);
  const [isLoadingCart, setIsLoadingCart] = useState(true);

  // Log errors to Sentry
  useSendErrorToSentryEffect(siteError);

  // TODO Log errors to console to aid debugging, remove in production
  useEffect(() => {
    if (siteError) {
      console.error({ error: siteError });
    }
  }, [siteError]);

  const refreshWebCart = async () => {
    try {
      const _webCart = await getCart();
      const _webSessionWithExpirations = await getWebSessionWithExpirations();

      setWebCart(_webCart);
      setWebSessionWithExpirations(_webSessionWithExpirations);
    } catch (error) {
      setWebCart(null);
      setWebSessionWithExpirations(null);
    } finally {
      setIsLoadingCart(false);
    }
  };

  // Refresh web cart data every minute
  useQuery(["webCart"], refreshWebCart, {
    staleTime: 60000,
  });

  return (
    <TessituraCartContext.Provider
      value={{
        siteError,
        setSiteError,
        webCart,
        webSessionWithExpirations,
        refreshWebCart,
        isLoadingCart,
      }}
    >
      {children}
    </TessituraCartContext.Provider>
  );
};
