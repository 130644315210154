import { useContext } from "react";
import MofoContext from "../context/MofoContext";
import { Saved, NotSaved } from "../components/Icons";
import { analyticsFavourited } from "../lib/analyticsTrackingFunctions";

const FavouriteButton = ({ event, className = "", position = undefined }) => {
  const { lite, favouriteAProgramListing, favourites, isTicketsPage } =
    useContext(MofoContext);

  const liteStyles = isTicketsPage ? "bg-cyan" : "bg-yellow";
  const nonLiteStyles = isTicketsPage
    ? "bg-cyan has-hover:hover:bg-cyan"
    : "bg-yellow has-hover:hover:bg-yellow";

  const favouriteSelClass = favourites.includes(event.slug)
    ? lite
      ? liteStyles
      : nonLiteStyles
    : "";
  return (
    <button
      className={
        `${favouriteSelClass} p-2 transition-all w-10 h-10 border border-black shadow-offset-black hover:shadow-offset-black-2 hover:bg-pink ` +
        className
      }
      onClick={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        analyticsFavourited(
          favourites.includes(event.slug),
          event.titleOverride || event.title,
          event,
          position
        );

        favouriteAProgramListing(event.slug);
      }}
    >
      {favourites.includes(event.slug) ? <Saved /> : <NotSaved />}
    </button>
  );
};

export default FavouriteButton;
