import { MF22ContentfulProductionSeason } from "../lib/types";
import {
  EighteenPlus,
  Accessible,
  Free,
  Ticketed,
  Auslan,
  AudioDescription,
  Tactile,
} from "./Icons";

export default function ProgramIcons({
  event,
  className = "",
}: {
  event: MF22ContentfulProductionSeason;
  className?: string;
}) {
  return (
    <div className={`flex gap-4 ${className}`}>
      {event ? (
        <>
          {event.tagsExtra?.includes("free") && (
            <Icon>
              <Free />
            </Icon>
          )}
          {event.tagsExtra?.includes("ticketed") && (
            <Icon>
              <Ticketed />
            </Icon>
          )}
          {event.tagsExtra?.includes("18+") && (
            <Icon>
              <EighteenPlus colour={"#FFF"} />
            </Icon>
          )}
          {event.tagsExtra?.includes("accessible") && (
            <Icon>
              <Accessible />
            </Icon>
          )}
          {event.tagsExtra?.includes("hobart") && (
            <Icon>
              <p className="text-white self-center">HOB</p>
            </Icon>
          )}
          {event.tagsExtra?.includes("launceston") && (
            <Icon>
              <p className="text-white self-center">LAUN</p>
            </Icon>
          )}
          {event.tagsExtra?.includes("auslan") && (
            <Icon>
              <Auslan />
            </Icon>
          )}
          {event.tagsExtra?.includes("audio") && (
            <Icon>
              <AudioDescription />
            </Icon>
          )}
          {event.tagsExtra?.includes("tactile") && (
            <Icon>
              <Tactile />
            </Icon>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

const Icon = ({ children }) => (
  <div className="bg-black w-8 h-8 text-xs flex flex-col items-center justify-center font-spaceMono font-bold">
    {children}
  </div>
);
