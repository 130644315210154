import getConfig from "next/config";

import { FilterableItem } from "./types";

const { publicRuntimeConfig } = getConfig();

export function filterForTicketed<T extends FilterableItem>(
  events: T[],
  free: boolean,
  ticketed: boolean
): T[] {
  return events.filter((v, i) => {
    if (ticketed && v.tagsExtra?.includes("ticketed")) {
      return v;
    }

    if (free && v.tagsExtra?.includes("free")) {
      return v;
    }

    if (!free && !ticketed) {
      return v;
    }

    return false;
  });
}

export function filterForHeart<T extends FilterableItem>(
  events: T[],
  heart: boolean,
  favourites: string[]
): T[] {
  return events.filter((v, i) => {
    //filter Favourited events
    if (heart) {
      if (favourites.includes(v.slug)) {
        return v;
      } else {
        return false;
      }
    } else {
      return v;
    }
  });
}

export function filterForLocation<T extends FilterableItem>(
  events: T[],
  launceston: boolean,
  hobart: boolean
) {
  return events.filter((v, i) => {
    //filter hobart & launceston
    if (launceston && v.tagsExtra?.includes("launceston")) {
      return v;
    } else if (hobart && v.tagsExtra?.includes("hobart")) {
      return v;
    } else if (!hobart && !launceston) {
      return v;
    }

    return false;
  });
}

export function filterForDates<T extends FilterableItem>(
  events: T[],
  dates: number[],
  hobart: boolean,
  hobartDates: number[],
  launceston: boolean,
  launcestonDates: number[]
): T[] {
  return events.filter((v, i) => {
    //Generate only relavent dates
    let relevantDates = dates?.filter((v, i) => {
      if (
        (hobart && hobartDates.includes(v)) ||
        (launceston && launcestonDates.includes(v)) ||
        (!launceston && !hobart)
      ) {
        return v;
      }
    });

    //SHOW all if no dates are selected
    if (relevantDates?.length === 0) {
      return true;
    } else {
      for (let i = 0; i < relevantDates?.length; i++) {
        let eventDates = getEventDates(
          filterDateTimesByTessituraPublishWebDateConstraints(v.dateTimes)
        );
        if (eventDates.includes(relevantDates[i])) {
          return true;
        }
      }
    }
    return false;
  });
}

function getEventDates(
  dates: {
    startDate: string;
    endDate?: string;
  }[] = []
) {
  let d = [];
  for (let i = 0; i < dates.length; i++) {
    let cDate = dates[i];
    let s = new Date(cDate.startDate);
    let e = new Date(cDate.endDate ? cDate.endDate : cDate.startDate);

    while (s <= e) {
      d.push(s.getDate());
      s.setDate(s.getDate() + 1);
    }
  }
  return d;
}

export function filterDateTimesByTessituraPublishWebDateConstraints<
  T extends {
    publishWebApiStartDate?: string;
    publishWebApiEndDate?: string;
  }
>(dateTimes: T[]) {
  // Don't filter if site is configured to ignore publish web dates
  if (publicRuntimeConfig.isProgramSiteIgnoringPublishWebDateConstraints) {
    return dateTimes;
  }

  return dateTimes?.filter((dateTime) => {
    // Identify "inactive" performance per Web publish date restrictions
    const now = new Date();
    let isActive = true;

    if (
      dateTime.publishWebApiStartDate &&
      new Date(dateTime.publishWebApiStartDate) > now
    ) {
      isActive = false;
    }

    if (
      dateTime.publishWebApiEndDate &&
      new Date(dateTime.publishWebApiEndDate) < now
    ) {
      isActive = false;
    }

    return isActive;
  });
}
