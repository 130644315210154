import { MF22ContentfulProductionSeason } from "./types";

export const goToRandomEvent = () => {
  window.dataLayer.push({
    event: "custom.generic.interaction",
    action: "Go to random event",
    label: undefined,
  });
};

export const filtersOpened = (open: boolean) => {
  window.dataLayer.push({
    event: "custom.generic.interaction",
    action: "Filters Open/Closed",
    label: `${open ? "CLOSED" : "OPEN"}`,
  });
};

export const favouritesCopyLinkPressed = () => {
  window.dataLayer.push({
    event: "custom.generic.interaction",
    action: "COPY FAVOURITES LINK",
    label: undefined,
  });
};

export const analyticsFilterBy = (
  on: boolean,
  parentLabel: string,
  name: string,
  date?: string
) => {
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  window.dataLayer.push({
    event: `custom.filter.${on ? "on" : "off"}`,
    action: `Program Filter`,
    parentLabel: parentLabel,
    label: name,
    data: date && {
      date: date,
      day: days[new Date(date).getDay()],
    },
  });
};

export const analyticsFavourited = (
  on: boolean,
  title: string,
  event: MF22ContentfulProductionSeason,
  index?: number
) => {
  window.dataLayer.push({
    event: `custom.toggle.${on ? "off" : "on"}`,
    group: "Planner",
    label: title,
    parentLabel: undefined,
    info: index && {
      position: index,
    },
    data: {
      preTitle: event?.pretitleExtra,
      performance: event?.titleOverride || event?.title,
      subtitle: event?.subtitleExtra,
      venue:
        (event?.facilities && event.facilities[0].titleOverride) ||
        event.facilities[0].title,
      eventType: event?.tagsExtra.includes("ticketed") ? "ticketed" : "free",
    },
  });
};


export const analyticsInformationOpen = (open: boolean, title: string) => {
  window.dataLayer.push({
    event: "custom.generic.interaction",
    action: `ACCORDION | CLICK`,
    label: `${title} | ${!open ? "OPEN" : "CLOSE"}`,
  });
};

export const analyticsGotoTopPressed = () => {
  window.dataLayer.push({
    event: "custom.generic.interaction",
    action: "GO TO TOP PRESSED",
    label: undefined,
  });
};
