import { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { decomposeHost } from "../lib/http";
const FILTERS_COOKIE_NAME = "FILTERS";

const MofoContext = createContext();

const MofoContextProvider = ({ children, isTicketsPage }) => {
  const [initStore, setInitStore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lite, setLite] = useState(true);
  const [favourites, setFavourites] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (initStore === false) {
      const isBrowser = typeof window !== "undefined";
      const existingLite = isBrowser
        ? sessionStorage.getItem("lite") === "false"
          ? false
          : true
        : false;

      const existingFavourites = isBrowser
        ? sessionStorage.getItem("favourites")
          ? JSON.parse(sessionStorage.getItem("favourites"))
          : []
        : [];

      setLite(existingLite);
      setFavourites(existingFavourites);

      if (isBrowser) {
        if (window.location.search) {
          setQuery(window.location.search);
        }
      }
    }
  }, [initStore]);

  // Force lite mode on tickets pages
  useEffect(() => {
    if (isTicketsPage) {
      setLite(true);
    }
  }, [isTicketsPage, lite]);

  useEffect(() => {
    window.sessionStorage.setItem("favourites", JSON.stringify(favourites));

    const { rootDomain } = decomposeHost(window.location.host);
    const filtersFromStorage = JSON.parse(
      Cookies.get(FILTERS_COOKIE_NAME) || "{}"
    );
    if (Object.keys(filtersFromStorage || {}).length > 0) {
      let hobart = filtersFromStorage.hobart || false;
      let launceston = filtersFromStorage.launceston || false;
      let free = filtersFromStorage.free || false;
      let ticketed = filtersFromStorage.ticketed || false;
      let dates = filtersFromStorage.dates || [];
      let heart = filtersFromStorage.heart || false;
      let filterByOpened = filtersFromStorage.filterByOpened || false;

      Cookies.set(
        FILTERS_COOKIE_NAME,
        JSON.stringify({
          hobart,
          launceston,
          free,
          ticketed,
          dates,
          heart,
          filterByOpened,
          favourites,
        }),
        { domain: rootDomain }
      );
    }
  }, [favourites]);

  const switchLite = (a) => {
    setLite((curr) => {
      sessionStorage.setItem("lite", !curr);
      return !curr;
    });
  };

  let favouriteAProgramListing = (slug) => {
    if (favourites) {
      if (favourites.includes(slug)) {
        setFavourites(favourites.filter((item) => item !== slug));
      } else {
        setFavourites((oldFaves) => [...oldFaves, slug]);
      }
    } else {
      setFavourites([slug]);
    }
  };

  return (
    <MofoContext.Provider
      value={{
        loading,
        setLoading,
        lite,
        switchLite,
        favourites,
        setFavourites,
        favouriteAProgramListing,
        isTicketsPage,
      }}
    >
      {children}
    </MofoContext.Provider>
  );
};

export { MofoContextProvider, MofoContext };
export default MofoContext;
