import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useRouter } from "next/router";
import getConfig from "next/config";
import Script from "next/script";
import Cookies from "js-cookie";

import { MofoContextProvider } from "../context/MofoContext";
import { TessituraCartContextProvider } from "../context/TessituraCartContext";
import { FetchError } from "../lib/http";

import "../styles/reset.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "../styles/globals.css";

import Layout from "../components/Layout";
import { useRedirectBrowserToOrFromTicketsSubdomainEffect } from "../lib/hooks";
import { TESSITURA_SOURCE_ID_COOKIE_NAME } from "../lib/constants";
import { H1 } from "../components/Styling";

import GoogleTagManager from "../lib/google-tag-manager";

const { publicRuntimeConfig } = getConfig();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // Do not retry 404 fetch errors
        if (error instanceof FetchError && error.info.status === 404) {
          return false;
        }
        return true;
      },
    },
  },
});

// Pass `err` as workaround for https://github.com/vercel/next.js/issues/8592
export default function MyApp({ Component, pageProps, err }) {
  const router = useRouter();

  const { query } = router;

  useRedirectBrowserToOrFromTicketsSubdomainEffect();

  // Save source ID from query string to cookie, provided it's an integer
  const { source: sourceId } = query;
  useEffect(() => {
    if (sourceId && parseInt(sourceId as string) !== NaN) {
      Cookies.set(TESSITURA_SOURCE_ID_COOKIE_NAME, sourceId);
    }
  }, [sourceId]);

  const { showTicketsComingSoonSplashPage, isTicketsPage, sponsors } =
    pageProps;

  return (
    <QueryClientProvider client={queryClient}>
      <TessituraCartContextProvider>
        <MofoContextProvider isTicketsPage={isTicketsPage}>
          {showTicketsComingSoonSplashPage ||
          (isTicketsPage && publicRuntimeConfig.ticketsDisabled) ? (
            <main
              className={
                "w-full h-full fixed top-0 left-0 bg-cyan " +
                "flex flex-col gap-10 justify-center items-center"
              }
            >
              <H1 title="Tickets Coming Soon" className="font-baseBloom" />
              <p>
                <a
                  href="https://www.youtube.com/watch?v=ejorQVy3m8E"
                  className="font-spaceMono"
                >
                  While you&apos;re waiting...
                </a>
              </p>
            </main>
          ) : (
            <Layout sponsors={sponsors}>
              {isTicketsPage && (
                <>
                  <Script src="/marker-io.tickets.js"></Script>

                  <Script
                    src="//static.queue-it.net/script/queueclient.min.js"
                    strategy="beforeInteractive"
                  />
                  <Script
                    src="//static.queue-it.net/script/queueconfigloader.min.js"
                    data-queueit-c="mona"
                    type="text/javascript"
                    strategy="beforeInteractive"
                  />
                </>
              )}
              <GoogleTagManager gtmId="GTM-NVBGTH" />
              <Component {...pageProps} err={err} />
            </Layout>
          )}
        </MofoContextProvider>
      </TessituraCartContextProvider>
    </QueryClientProvider>
  );
}
