import { MF22ContentfulProductionSeason } from "../lib/types";
import { Price } from "./Icons";

export default function ProductionSeasonPrice({
  productionSeason,
  override,
}: {
  productionSeason: MF22ContentfulProductionSeason;
  override?: String;
}) {
  if (
    !productionSeason.costSummaryExtra &&
    !productionSeason?.tagsExtra?.includes("free")
  ) {
    return null;
  }

  return (
    <div className="flex mb-2">
      <div className="w-4 flex-shrink-0 mr-2">
        <Price />
      </div>
      {override
        ? override
        : productionSeason.costSummaryExtra
        ? productionSeason.costSummaryExtra
        : productionSeason.tagsExtra?.includes("free")
        ? "Free"
        : "???"}
    </div>
  );
}
