import { useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";
import { format as formatDate, parseISO } from "date-fns";
import Cookies from "js-cookie";
import Link from "next/link";

import { MofoContext } from "../context/MofoContext";
import {
  FilterableItem,
  FilteredItem,
  SponsorsQueryResult,
} from "../lib/types";
import {
  filterForDates,
  filterForTicketed,
  filterForHeart,
  filterForLocation,
} from "../lib/filterers";
import Button from "./Button";
import Filterer from "./Filterer";
import Sponsors from "./Sponsors";
import { betterFetch, decomposeHost } from "../lib/http";
import { filterDateTimesByTessituraPublishWebDateConstraints } from "../lib/filterers";
import Img from "next/image";
import { favouritesCopyLinkPressed } from "../lib/analyticsTrackingFunctions";

const FILTERS_COOKIE_NAME = "FILTERS";

export function FilteredListing<T>({
  items,
  FilteredItemsComponent,
  heading = "",
  bgColour = "bg-yellow",
  sponsors,
}: {
  items: (T & FilterableItem)[];
  FilteredItemsComponent: React.JSXElementConstructor<{
    filteredItems: (T & FilteredItem)[];
    isDateMode?: boolean;
  }>;
  heading?: string;
  bgColour?: string;
  sponsors?: SponsorsQueryResult;
}) {
  let Router = useRouter();
  let { query } = Router;

  // State
  const [hobart, setHobart] = useState(false);
  const [launceston, setLaunceston] = useState(false);
  const [free, setFree] = useState(false);
  const [ticketed, setTicketed] = useState(false);
  const [dates, setDates] = useState([]);
  const [dateMode, setDateMode] = useState(true);
  const [heart, setHeart] = useState(false);
  const [filterByOpened, setFilterByOpened] = useState(false);
  const [randomEvent, setRandomEvent] = useState(null);
  // A check to avoid setting cookie with initial state values.
  const [cookieLoadAttempted, setCookieLoadAttempted] = useState(false);

  const [copyLinkText, setCopyLinkText] = useState(
    "Get a link to your favourites"
  );

  const { lite, favourites, setFavourites, isTicketsPage } =
    useContext(MofoContext);

  //construct dates for filters
  //using a set incase there is duplicated dates for both locations.
  const hobartDates = [26, 27, 28, 29, 30, 31];
  const launcestonDates = [21, 22, 23, 24, 25];
  const allDatesSet = new Set();
  for (let i = 0; i < launcestonDates.length; i++) {
    allDatesSet.add(launcestonDates[i]);
  }
  for (let i = 0; i < hobartDates.length; i++) {
    allDatesSet.add(hobartDates[i]);
  }
  const allDates = Array.from(allDatesSet);

  // Pull state out of cookie on load
  useEffect(() => {
    setCookieLoadAttempted(true);
    const filtersFromStorage = JSON.parse(
      Cookies.get(FILTERS_COOKIE_NAME) || "{}"
    );
    if (
      Object.keys(filtersFromStorage || {}).length > 0 &&
      Object.keys(query).length === 0
    ) {
      let thereIsAFilterSelected =
        filtersFromStorage.hobart ||
        filtersFromStorage.launceston ||
        filtersFromStorage.free ||
        filtersFromStorage.ticketed ||
        filtersFromStorage.dates.length > 0 ||
        filtersFromStorage.heart;

      setHobart(filtersFromStorage.hobart || false);
      setLaunceston(filtersFromStorage.launceston || false);
      setFree(filtersFromStorage.free || false);
      setTicketed(filtersFromStorage.ticketed || false);
      setDates(filtersFromStorage.dates || []);
      setHeart(filtersFromStorage.heart || false);

      setFilterByOpened(
        thereIsAFilterSelected
          ? true
          : filtersFromStorage.filterByOpened || false
      );
      setFavourites(filtersFromStorage.favourites || []);
    }
  }, []);

  // Set state into cookie on change
  useEffect(() => {
    const { rootDomain } = decomposeHost(window.location.host);

    if (!cookieLoadAttempted) return;
    if (Object.keys(query).length > 0) return;

    Cookies.set(
      FILTERS_COOKIE_NAME,
      JSON.stringify({
        hobart,
        launceston,
        free,
        ticketed,
        dates,
        heart,
        filterByOpened,
        favourites,
      }),
      { domain: rootDomain }
    );
  }, [
    hobart,
    launceston,
    free,
    ticketed,
    dates,
    heart,
    favourites,
    filterByOpened,
    query,
  ]);

  // useEffect(() => {
  //   query.e && setHeart(true);
  //   query.h && setHobart(true);
  //   query.l && setLaunceston(true);
  //   //setDateMode(query.date ? true : false);
  //   query.free && setFree(true);
  //   query.ticket && setTicketed(true);
  //   query.e !== undefined &&
  //     setFavourites(typeof query.e === "string" ? query.e.split(",") : query.e);

  //   try {
  //     query.date &&
  //       setDates([
  //         typeof query.date === "string"
  //           ? parseInt(query.date)
  //           : parseInt(query.date[0]),
  //       ]);
  //   } catch (e) {}
  // }, [query, setFavourites]);

  useEffect(() => {
    if (Object.keys(query).length === 0) return;

    // console.log(query);

    let getBoolVal = (q) => {
      return q !== undefined ? true : false;
    };
    setFilterByOpened(true);
    setHeart(getBoolVal(query.e));
    setHobart(getBoolVal(query.h));
    setLaunceston(getBoolVal(query.l));
    setFree(getBoolVal(query.free));
    setTicketed(getBoolVal(query.ticket));
    setFavourites(
      getBoolVal(query.e)
        ? typeof query.e === "string"
          ? query.e.split(",")
          : query.e
        : []
    );
    try {
      setDates(
        getBoolVal(query.date)
          ? typeof query.date === "string"
            ? [parseInt(query.date)]
            : [parseInt(query.date[0])]
          : []
      );
    } catch (e) {}
  }, [query]);

  // Disable "Ticketed" and "Free" filters on tickets page, so user who sets
  // these on the Program site doesn't have them irreversibly applied on the
  // tickets site
  useEffect(() => {
    if (isTicketsPage) {
      setTicketed(false);
      setFree(false);
    }
  }, [ticketed, free, isTicketsPage]);

  // Set selected dates
  let datesUpdate = (value) => {
    if (dates.includes(value)) setDates([]);
    else setDates([value]);
  };

  useEffect(() => {
    async function loadARandomEvent() {
      let r = await betterFetch("/api/program/random-event");
      setRandomEvent(r.slug);
    }
    loadARandomEvent();
  }, [setRandomEvent]);

  let getStartTimeForDate = (
    datesToCheck: {
      startDate: string;
      endDate?: string;
    }[] = []
  ): string => {
    if (dates.length > 0 && datesToCheck) {
      let filteredDates = datesToCheck?.filter((v, i) => {
        let sDate = new Date(v.startDate).getDate();
        let eDate = v.endDate ? new Date(v.endDate).getDate() : sDate;

        if (sDate <= dates[0] && eDate >= dates[0]) {
          return v;
        }
      });

      if (filteredDates.length > 0) {
        return formatDate(parseISO(filteredDates[0].startDate), "HH");
      }
    }
    return "9999";
  };

  // Results are filterable items with extra fields per `FilteredItem`
  const results: (T & FilteredItem)[] = [];

  if (items.length > 0) {
    let filteredItems: (T & FilterableItem)[] = items || [];
    filteredItems = filterForTicketed(filteredItems, free, ticketed);
    filteredItems = filterForHeart(filteredItems, heart, favourites);
    filteredItems = filterForLocation(filteredItems, launceston, hobart);
    filteredItems = filterForDates(
      filteredItems,
      dates,
      hobart,
      hobartDates,
      launceston,
      launcestonDates
    );

    // Populate results and decorate items with filtering result info
    filteredItems.forEach((item) => {
      const isFavourite = !!favourites.includes(item.slug);
      const startTime = getStartTimeForDate(
        filterDateTimesByTessituraPublishWebDateConstraints(item.dateTimes)
      );

      results.push({
        ...item,
        isFavourite,
        startTime,
      });
    });

    // Sort events by start time, with fallback to alphabetical, _always_ on
    // the Tickets site or when a date filter is applied on the Program site
    if (dates?.length > 0 || isTicketsPage) {
      results.sort((a, b) => {
        if (a.startTime > b.startTime) {
          return 1;
        } else if (a.startTime === b.startTime) {
          if (isTicketsPage) {
            // Sort alphabetically for matching start times on tickets
            return a.title.localeCompare(b.title);
          } else {
            // Retain original order on program site.
            return 0;
          }
        }
        return -1;
      });
    }
  }

  const updateGetLinkText = () => {
    setCopyLinkText("Copied to your clipboard");
    setTimeout(() => {
      setCopyLinkText("Get a link to your favourites");
    }, 4000);
  };

  return (
    <div
      className={`flex flex-col items-center w-full bg-fixed ${
        !isTicketsPage ? "min-h-screen" : ""
      }
        ${
          launceston && !hobart && !isTicketsPage
            ? "bg-gradient-to-b from-purple via-yellow to-green"
            : ""
        }
        ${
          hobart && !launceston && !isTicketsPage
            ? "bg-gradient-to-b from-green via-blue to-orange"
            : ""
        }
        ${
          (hobart && launceston && !isTicketsPage) ||
          (!hobart && !launceston && !isTicketsPage)
            ? "bg-gradient-to-b from-purple via-green to-orange"
            : ""
        }
        `}
    >
      <Filterer
        v={{
          hobart,
          setHobart,
          launceston,
          setLaunceston,
          dates,
          datesUpdate,
          setDates,
          days: allDates,
          hobartDates,
          launcestonDates,
          heart,
          setHeart,
          free,
          setFree,
          ticketed,
          setTicketed,
          dateMode,
          setDateMode,
        }}
        filterByOpened={filterByOpened}
        setFilterByOpened={setFilterByOpened}
        heading={heading}
      />

      {heart && !isTicketsPage && results?.length > 0 && (
        <Button
          width="my-5 relative"
          buttonText={copyLinkText}
          onClick={() => {
            favouritesCopyLinkPressed();
            updateGetLinkText();
            navigator.clipboard.writeText(
              `https://monafoma.net.au/?e=${favourites.toString()}`
            );
          }}
        />
      )}
      {!isTicketsPage &&
        !hobart &&
        !launceston &&
        !free &&
        !ticketed &&
        !heart &&
        dates.length === 0 && (
          <>
            <div className="max-w-md w-full my-4 px-6 lg:hidden">
              <Img
                src="https://mofo2022.s3.ap-southeast-2.amazonaws.com/overlays/Mofo-Web-Top-Listing-portrait.png"
                layout="responsive"
                width="197"
                height="264"
              ></Img>
            </div>
            <div className="w-full m-4 hidden lg:block lg:max-w-4xl 2xl:max-w-7xl">
              <Img
                src="https://mofo2022.s3.ap-southeast-2.amazonaws.com/overlays/Mofo-Web-Top-Listing-landscape.png"
                layout="responsive"
                width="1344"
                height="600"
              ></Img>
            </div>
          </>
        )}
      {!isTicketsPage && results?.length === 0 && (
        <div className="relative min-h-screen max-w-3xl text-center">
          <h3>
            No events found—try favouriting some using the heart button and
            you&apos;ll never be lonely again.{" "}
            {randomEvent && (
              <>
                Or check out a{" "}
                <Link href={"/program/" + randomEvent}>
                  <a>random event</a>
                </Link>
              </>
            )}
            .
          </h3>
        </div>
      )}

      <FilteredItemsComponent filteredItems={results} isDateMode={dateMode} />
      {!isTicketsPage && <Sponsors />}
    </div>
  );
}
