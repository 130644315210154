import { STATUS_CODES } from "http";

import { getByKeyIgnoreCase } from "./utils";

export interface BetterErrorOptions {
  info?: any;
  cause?: Error;
}

export interface TBetterError extends Error {
  info?: any;
  cause?: Error;
  fullStack?: String;
}

/**
 * Improved `Error` class:
 * - reports its `name` accurately when subclassed
 * - accpets extra optional parameters:
 *   - `cause` to capture the ancestor/original error
 *   - `info` to capture arbitrary additional information that might be useful
 *     downstream to debug, log, or recover from the error
 * - `fullStack()` function returns a text description including all ancestor
 *   errors captured with the `cause` parameter.
 */
export class BetterError extends Error {
  readonly info?: any;
  readonly cause?: TBetterError;

  constructor(message, { info, cause }: BetterErrorOptions = {}) {
    super(message);

    this.info = info;

    if (!!cause && !(cause instanceof Error)) {
      throw new Error("Optional 'cause' argument must be an Error class");
    }
    this.cause = cause;
  }

  /**
   * Return the real name of this error class, or subclasses, in text
   * representations instead of just 'Error'
   */
  get name() {
    // TODO This is only a *somewhat* reliable way to get the real class name
    return this.constructor.name;
  }

  /**
   * Return this error's `stack` text and also include the `stack` output of
   * any nested `cause` errors.
   */
  get fullStack() {
    let stackText = this.stack;
    let cause = this.cause;
    while (!!cause) {
      stackText += `\nCaused by: ${cause.stack || cause}`;
      cause = cause.cause;
    }
    return stackText;
  }
}

/**
 * Represent an error that was the fault of the client/user/browser, not the
 * server, such as invalid data provided; in other words, any 4xx-like error.
 */
export class ClientError extends BetterError {}

/**
 * Represent an error that was the fault of the server, not the client; in
 * other words, any 5xx-like error.
 */
export class ServerError extends BetterError {}

/**
 * Represent an HTTP error status code to return e.g. a 4xx or 5xx error.
 */
export class HTTPStatusError extends BetterError {
  constructor(httpStatusCode, { info, cause }: BetterErrorOptions = {}) {
    const statusText = STATUS_CODES[httpStatusCode];

    super(statusText, {
      info: { statusCode: httpStatusCode, statusText, ...info },
      cause,
    });
  }
}

function simplifyErrorData(error) {
  if (!error) {
    return;
  }

  return {
    type: error.name,
    message: error.message,
    info: error.info,
    cause: simplifyErrorData(error.cause),
  };
}

/**
 * Wrapper function for Next.js API `handler` functions to return the object
 * `{ errors: [] }` as JSON data instead of an HTML error page, unless the
 * client prefers HTML.
 *
 * If you generate, or catch-and-wrap, server errors with a `ClientError` this
 * function will return a HTTP 400 response instead of 500.
 *
 * @param {*} handler
 * @returns
 */
export function withErrorResponseAsJSON(handler) {
  return async function returnErrorsAsJson(req, res) {
    try {
      return await handler(req, res);
    } catch (error) {
      // Check content types the client accepts or prefers
      const accept = getByKeyIgnoreCase<string>(req.headers, "Accept") || "";
      const acceptHTMLIndex = accept.toLowerCase().indexOf("text/html");
      const acceptJSONIndex = accept.toLowerCase().indexOf("application/json");

      // Prefers or accepts HTML not JSON? Re-raise the error to return the
      // usual HTML error page
      if (
        acceptHTMLIndex >= 0 &&
        (acceptJSONIndex < 0 || acceptJSONIndex > acceptHTMLIndex)
      ) {
        throw error;
      }

      // TODO Log to console for now to help debug
      console.error({ error });

      const errorData = simplifyErrorData(error);

      // NOTE: We always return plural `errors` instead of a single one, for
      // more flexibility in other situations where we want to return multiple
      // error results without needing to check for *both* `error` and `errors`
      // on the client side.
      if (error instanceof HTTPStatusError) {
        res.status(error.info.statusCode).json({ errors: [errorData] });
      } else if (error instanceof ClientError) {
        res.status(400).json({ errors: [errorData] });
      } else {
        res.status(500).json({ errors: [errorData] });
      }
    }
  };
}
