import { useContext } from "react";
import { MofoContext } from "../context/MofoContext";
import { Saved } from "../components/Icons";
import { H1 } from "../components/Styling";
import Button from "./Button";
import {
  analyticsFilterBy,
  filtersOpened,
} from "../lib/analyticsTrackingFunctions";

const Filterer = ({ v, filterByOpened, setFilterByOpened, heading = "" }) => {
  const { lite, isTicketsPage } = useContext(MofoContext);

  const bgColour = isTicketsPage ? "bg-white" : "bg-yellow";
  const defaultSelectedClasses = isTicketsPage
    ? "bg-cyan text-black"
    : "bg-black text-yellow";

  const clearFilters = () => {
    v.setHobart(false);
    v.setLaunceston(false);
    v.setDates([]);
    v.setTicketed(false);
    v.setFree(false);
    v.setHeart(false);
  };

  const filtersSelected =
    v.hobart ||
    v.launceston ||
    v.ticketed ||
    v.free ||
    v.dates?.length > 0 ||
    v.heart;

  return (
    <div
      className={
        `${bgColour} w-full py-2 px-4 mb-4 sm:mb-6 sm:sticky top-20 z-10 md:pb-4 items-center flex flex-col justify-center` +
        `${!isTicketsPage ? " border-b border-black" : ""}`
      }
    >
      {heading && <H1 title={heading} className="my-4" />}

      <Button
        onClick={() => {
          filtersOpened(filterByOpened);
          setFilterByOpened((old) => !old);
        }}
        className="text-center relative"
        buttonText={"Filter"}
        width="mb-2"
      />

      {filterByOpened && (
        <>
          <div className="grid grid-cols-6 sm:grid-cols-10 w-full sm:w-96 gap-px bg-black border border-black">
            <FilterButton
              selectedClasses="bg-gradient-to-r from-purple via-yellow to-green "
              unselectedClasses={bgColour}
              additionalClasses={`${
                isTicketsPage
                  ? "col-span-2 sm:col-span-4"
                  : "col-span-3 sm:col-span-5"
              } border-r-0`}
              selected={v.launceston}
              onClick={() => {
                analyticsFilterBy(!v.launceston, "Filter By", "Launceston");
                v.setLaunceston((oldL) => !oldL);
              }}
            >
              Launceston
            </FilterButton>
            <FilterButton
              selectedClasses="bg-gradient-to-r from-green via-blue to-orange"
              unselectedClasses={bgColour}
              additionalClasses={`${
                isTicketsPage
                  ? "col-span-2 sm:col-span-4"
                  : "col-span-3 sm:col-span-5"
              }`}
              selected={v.hobart}
              onClick={() => {
                analyticsFilterBy(!v.hobart, "Filter By", "Hobart");
                v.setHobart((oldH) => !oldH);
              }}
            >
              Hobart
            </FilterButton>

            {isTicketsPage && (
              <FilterButton
                unselectedClasses={bgColour}
                selectedClasses={defaultSelectedClasses}
                additionalClasses="col-span-2 sm:col-span-2 border-t-0"
                selected={v.heart}
                onClick={() => {
                  analyticsFilterBy(!v.heart, "Filter By", "Favourited");
                  v.setHeart((oldH) => !oldH);
                }}
              >
                {<Saved colour={!v.heart ? "#000" : "#FFF"} />}
              </FilterButton>
            )}

            {!isTicketsPage && (
              <>
                <FilterButton
                  unselectedClasses={bgColour}
                  selectedClasses={defaultSelectedClasses}
                  additionalClasses="col-span-2 sm:col-span-4 border-t-0 border-r-0"
                  selected={v.ticketed}
                  onClick={() => {
                    analyticsFilterBy(!v.ticketed, "Filter By", "Ticketed");
                    v.setTicketed((oldT) => !oldT);
                  }}
                >
                  Ticketed
                </FilterButton>
                <FilterButton
                  unselectedClasses={bgColour}
                  selectedClasses={defaultSelectedClasses}
                  additionalClasses="col-span-2 sm:col-span-4 border-t-0 border-r-0"
                  selected={v.free}
                  onClick={() => {
                    analyticsFilterBy(!v.free, "Filter By", "Free");
                    v.setFree((oldF) => !oldF);
                  }}
                >
                  Free
                </FilterButton>
                <FilterButton
                  unselectedClasses={bgColour}
                  selectedClasses={defaultSelectedClasses}
                  additionalClasses="col-span-2 sm:col-span-2 border-t-0"
                  selected={v.heart}
                  onClick={() => {
                    analyticsFilterBy(!v.heart, "Filter By", "Favourited");
                    v.setHeart((oldH) => !oldH);
                  }}
                >
                  {<Saved colour={!v.heart ? "#000" : "#FFF"} />}
                </FilterButton>
              </>
            )}
          </div>
          <div className="grid grid-cols-6 sm:grid-cols-11 w-full sm:w-96 gap-px bg-black border border-black border-t-0">
            {v.dateMode &&
              v.days.map((item, i) => {
                let d = new Date(2022, 0, item);
                let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

                return (
                  <FilterButton
                    key={item}
                    onClick={() => {
                      analyticsFilterBy(
                        !v.dates.includes(item),
                        "date",
                        `2022-01-${item}`,
                        `2022-01-${item}`
                      );
                      v.datesUpdate(item);
                    }}
                    selected={v.dates.includes(item)}
                    disabled={
                      (v.launceston &&
                        !v.hobart &&
                        !v.launcestonDates.includes(item)) ||
                      (v.hobart &&
                        !v.launceston &&
                        !v.hobartDates.includes(item))
                    }
                    additionalClasses="text-xs"
                    selectedClasses={defaultSelectedClasses}
                    unselectedClasses={bgColour}
                  >
                    <>
                      <span>
                        {days[d.getDay()]}
                        <br />
                      </span>
                      <span>{item}</span>
                    </>
                  </FilterButton>
                );
              })}
            {v.days.length % 2 !== 0 && (
              <div className="bg-yellow block sm:hidden"></div>
            )}
          </div>
          {filtersSelected && (
            <button
              onClick={() => clearFilters()}
              className={
                "mt-4 text-xs px-4 transition-all duration-500 " +
                (isTicketsPage
                  ? "has-hover:hover:bg-black has-hover:hover:text-cyan"
                  : "has-hover:hover:bg-black has-hover:hover:text-yellow")
              }
            >
              Clear filters
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Filterer;

const FilterButton = ({
  selected,
  selectedClasses = isTicketsPage
    ? "bg-cyan text-black"
    : "bg-black text-yellow",
  unselectedClasses = "bg-yellow",
  additionalClasses = "col-span-1",
  children,
  onClick,
  disabled,
}) => {
  const { lite, isTicketsPage } = useContext(MofoContext);

  selectedClasses = lite ? "bg-black text-white" : selectedClasses;

  const buttonStyles = `w-full h-full py-2 p-1 ${
    selected ? selectedClasses : unselectedClasses
  } ${additionalClasses} disabled:opacity-20 disabled:cursor-not-allowed`;

  return (
    <div className={`${additionalClasses} ${unselectedClasses}`}>
      <button onClick={onClick} className={buttonStyles} disabled={disabled}>
        {children}
      </button>
    </div>
  );
};
